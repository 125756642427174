const Menu = {
  gravitoAdmin: [
    {
      key: "routekey-customerdashboard",
      label: "sidebar.customerdashboard",
      path: "/app/customerdashboard",
      icon: <i className="me-1 bi bi-house-fill"></i>,
      vissbleOnSmallScrren: false,
    },
    {
      key: "routekey-reporting",
      label: "sidebar.reporting",
      path: "/app/reports",
      icon: <i className="me-1 bi bi-briefcase-fill"></i>,

      vissbleOnSmallScrren: false,
    },
    {
      key: "routekey-config",
      label: "sidebar.config",
      path: "/app/config",
      icon: <i className="me-1 bi bi-pc-display"></i>,
      subMenu: [
        {
          key: "routekey-config-profiles",
          label: "Profiles",
          path: "/app/config/profiles",
          icon: <i className="me-1 bi bi-briefcase-fill"></i>,
          vissbleOnSmallScrren: false,
        },
        {
          key: "routekey-config-devices",
          label: "Devices",
          path: "/app/devices",
          icon: <i className="me-1 bi bi-briefcase-fill"></i>,
          vissbleOnSmallScrren: false,
        },
        {
          key: "routekey-config-upload",
          label: "Upload",
          path: "/upload",
          icon: <i className="me-1 bi bi-briefcase-fill"></i>,
          vissbleOnSmallScrren: false,
        },
      ],
      vissbleOnSmallScrren: false,
    },
    {
      key: "routekey-users",
      label: "sidebar.users",
      path: "/users",
      icon: <i className="me-1 bi bi-pc-display"></i>,
      subMenu: [
        {
          key: "routekey-users-manageRoles",
          label: "Manage Roles",
          path: "/roles/list",
          icon: <i className="me-1 bi bi-briefcase-fill"></i>,
          vissbleOnSmallScrren: false,
        },
        {
          key: "routekey-users-manageUsers",
          label: "Manage Users",
          path: "/users/list",
          icon: <i className="me-1 bi bi-briefcase-fill"></i>,
          vissbleOnSmallScrren: false,
        },
      ],
      vissbleOnSmallScrren: false,
    },
    {
      key: "routekey-customers",
      label: "sidebar.customers",
      path: "/customers",
      icon: <i className="me-1 bi bi-pc-display"></i>,
      subMenu: [
        {
          key: "routekey-customers-manageCustomers",
          label: "Manage Customers",
          path: "/customers/list",
          icon: <i className="me-1 bi bi-briefcase-fill"></i>,
          vissbleOnSmallScrren: false,
        },
        {
          key: "routekey-customers-manageDomains",
          label: "Manage Domains",
          path: "/domains/list",
          icon: <i className="me-1 bi bi-briefcase-fill"></i>,
          vissbleOnSmallScrren: false,
        },
        {
          key: "routekey-manageDomainGroups",
          label: "sidebar.manageDomainGroups",
          path: "/app/customerdomaingroups",
          icon: <i className="me-1 bi bi-briefcase-fill"></i>,
          vissbleOnSmallScrren: true,
        },
        {
          key: "routekey-customers-manageVouchers",
          label: "Manage Vouchers",
          path: "/vouchers",
          icon: <i className="me-1 bi bi-briefcase-fill"></i>,
          vissbleOnSmallScrren: false,
        },
        {
          key: "routekey-customers-manageRequests",
          label: "Manage Requests",
          path: "/requests",
          icon: <i className="me-1 bi bi-briefcase-fill"></i>,
          vissbleOnSmallScrren: false,
        },
      ],
      vissbleOnSmallScrren: false,
    },
    {
      key: "routekey-partners",
      label: "sidebar.partners",
      path: "/partners/list",
      icon: <i className="me-1 bi bi-people-fill"></i>,
      vissbleOnSmallScrren: false,
    },
    {
      key: "routekey-features",
      label: "sidebar.features",
      path: "/features",
      icon: <i className="me-1 bi bi-people-fill"></i>,
      subMenu: [
        {
          key: "routekey-features-cookieScan",
          label: "Cookie Scan",
          path: "/cookiescan/list",
          icon: <i className="me-1 bi bi-briefcase-fill"></i>,
          vissbleOnSmallScrren: false,
        },
        {
          key: "routekey-features-cookieRepo",
          label: "Cookie Repo",
          path: "/cookierepo/list",
          icon: <i className="me-1 bi bi-briefcase-fill"></i>,
          vissbleOnSmallScrren: false,
        },
      ],
      vissbleOnSmallScrren: false,
    },
    {
      key: "routekey-gravitoAdmin",
      label: "sidebar.gravitoAdmin",
      path: "/gravitoAdmin",
      icon: <i className="me-1 bi bi-people-fill"></i>,
      subMenu: [
        {
          key: "routekey-gravitoAdmin-domainCounts",
          label: "Domain Counts",
          path: "/domainCounts",
          icon: <i className="me-1 bi bi-briefcase-fill"></i>,
          vissbleOnSmallScrren: false,
        },
        {
          key: "routekey-gravitoAdmin-certCheck",
          label: "Cert Check",
          path: "/certCheck",
          icon: <i className="me-1 bi bi-briefcase-fill"></i>,
          vissbleOnSmallScrren: false,
        },
        {
          key: "routekey-gravitoAdmin-firstPartyApps",
          label: "First Party Apps",
          path: "/firstPartyApps/list",
          icon: <i className="me-1 bi bi-briefcase-fill"></i>,
          vissbleOnSmallScrren: false,
        },
      ],
      vissbleOnSmallScrren: false,
    },
    {
      key: "routekey-cookieSettings",
      label: "sidebar.cookieSettings",
      path: "/cookieSettings",
      icon: <i className="me-1 bi bi-people-fill"></i>,
      vissbleOnSmallScrren: false,
    },
  ],
  customerAdmin: [
    {
      key: "routekey-cmp",
      label: "sidebar.cmp",
      path: "/app/cmp",
      subMenu: [
        {
          key: "routekey-gravitoCMPconfig",
          label: "sidebar.gravitoCMPconfig",
          path: "/app/gravitoCMPconfig",
          icon: <i className="me-1 bi bi-briefcase-fill"></i>,
          vissbleOnSmallScrren: true,
        },
        {
          key: "routekey-tcfConfig",
          label: "sidebar.tcfConfig",
          path: "/app/tcfconfig",
          icon: <i className="me-1 bi bi-briefcase-fill"></i>,
          vissbleOnSmallScrren: true,
        },
      ],
      icon: <i className="me-1 bi bi-people-fill"></i>,
      vissbleOnSmallScrren: true,
    },
    {
      key: "routekey-domains",
      label: "sidebar.domains",
      path: "/app/domains",
      subMenu: [
        {
          key: "routekey-manageDomains",
          label: "sidebar.manageDomains",
          path: "/app/customerdomains",
          icon: <i className="me-1 bi bi-briefcase-fill"></i>,
          vissbleOnSmallScrren: true,
        },
        {
          key: "routekey-manageDomainGroups",
          label: "sidebar.manageDomainGroups",
          path: "/app/customerdomaingroups",
          icon: <i className="me-1 bi bi-briefcase-fill"></i>,
          vissbleOnSmallScrren: true,
        },
        {
          key: "routekey-firstPartyDomains",
          label: "sidebar.firstPartyDomains",
          path: "/app/managecustomdomains",
          icon: <i className="me-1 bi bi-briefcase-fill"></i>,
          vissbleOnSmallScrren: true,
        },
      ],
      icon: <i className="me-1 bi bi-people-fill"></i>,
      vissbleOnSmallScrren: true,
    },
    {
      key: "routekey-features",
      label: "sidebar.features",
      path: "/app/features",
      subMenu: [
        {
          key: "routekey-iAm&Apps",
          label: "sidebar.iAm&Apps",
          path: "/app/applications",
          icon: <i className="me-1 bi bi-briefcase-fill"></i>,
          vissbleOnSmallScrren: true,
        },
        // {
        //   key: "routekey-crm",
        //   label: "sidebar.crm",
        //   path: "/app/domainprofiles",
        //   icon: <i className="me-1 bi bi-briefcase-fill"></i>,
        //   vissbleOnSmallScrren: false,
        // },
        {
          key: "routekey-connectors",
          label: "sidebar.connectors",
          path: "/app/connectors",
          icon: <i className="me-1 bi bi-briefcase-fill"></i>,
          vissbleOnSmallScrren: true,
        },
        // {
        //   key: "routekey-cookieScan",
        //   label: "sidebar.cookieScan",
        //   path: "/app/cookiescan",
        //   icon: <i className="me-1 bi bi-briefcase-fill"></i>,
        //   vissbleOnSmallScrren: false,
        // },
        // {
        //   key: "routekey-cookieRepo",
        //   label: "sidebar.cookieRepo",
        //   path: "/app/cookierepo",
        //   icon: <i className="me-1 bi bi-briefcase-fill"></i>,
        //   vissbleOnSmallScrren: false,
        // },
        {
          key: "routekey-gvlUpdates",
          label: "sidebar.gvlUpdates",
          path: "/app/gvlupdates",
          icon: <i className="me-1 bi bi-briefcase-fill"></i>,
          vissbleOnSmallScrren: true,
        },
        {
          key: "routekey-firstPartyConfigurator",
          label: "sidebar.firstPartyConfigurator",
          path: "/app/firstpartyconfigurator",
          icon: <i className="me-1 bi bi-briefcase-fill"></i>,
          vissbleOnSmallScrren: true,
        },
      ],
      icon: <i className="me-1 bi bi-people-fill"></i>,
      vissbleOnSmallScrren: true,
    },
    {
      key: "routekey-customerdashboard",
      label: "sidebar.customerdashboard",
      path: "/app/customerdashboard",
      icon: <i className="me-1 bi bi-house-fill"></i>,
      vissbleOnSmallScrren: true,
    },
    {
      key: "routekey-reporting",
      label: "sidebar.reporting",
      path: "/app/reports",
      icon: <i className="me-1 bi bi-briefcase-fill"></i>,
      vissbleOnSmallScrren: true,
    },
    // {
    //   key: "routekey-cookieSettings",
    //   label: "sidebar.cookieSettings",
    //   path: "/app/*",
    //   icon: <i className="me-1 bi bi-people-fill"></i>,
    //   vissbleOnSmallScrren: false,
    // },
    {
      key: "routekey-features",
      label: "sidebar.cookieReport",
      path: "/app/cookieReport",
      subMenu: [
        {
          key: "routekey-cookieScan",
          label: "sidebar.cookieScan",
          path: "/app/cookiescan",
          icon: <i className="me-1 bi bi-briefcase-fill"></i>,
          vissbleOnSmallScrren: true,
        },
        {
          key: "routekey-cookieRepo",
          label: "sidebar.cookieRepo",
          path: "/app/cookierepo",
          icon: <i className="me-1 bi bi-briefcase-fill"></i>,
          vissbleOnSmallScrren: true,
        },
      ],
      icon: <i className="me-1 bi bi-people-fill"></i>,
      vissbleOnSmallScrren: true,
    },
  ],
  selfService: [
    {
      key: "routekey-cmp",
      label: "sidebar.cmp",
      path: "/app/cmp",
      subMenu: [
        {
          key: "routekey-gravitoCMPconfig",
          label: "sidebar.gravitoCMPconfig",
          path: "/app/gravitoCMPconfig",
          icon: <i className="me-1 bi bi-briefcase-fill"></i>,
          vissbleOnSmallScrren: true,
        },
      ],
      icon: <i className="me-1 bi bi-people-fill"></i>,
      vissbleOnSmallScrren: true,
    },
    {
      key: "routekey-domains",
      label: "sidebar.domains",
      path: "/app/domains",
      subMenu: [
        {
          key: "routekey-manageDomains",
          label: "sidebar.manageDomains",
          path: "/app/customerdomains",
          icon: <i className="me-1 bi bi-briefcase-fill"></i>,
          vissbleOnSmallScrren: true,
        },
        {
          key: "routekey-manageDomainGroups",
          label: "sidebar.manageDomainGroups",
          path: "/app/customerdomaingroups",
          icon: <i className="me-1 bi bi-briefcase-fill"></i>,
          vissbleOnSmallScrren: true,
        },
        {
          key: "routekey-firstPartyDomains",
          label: "sidebar.firstPartyDomains",
          path: "/app/managecustomdomains",
          icon: <i className="me-1 bi bi-briefcase-fill"></i>,
          vissbleOnSmallScrren: true,
        },
      ],
      icon: <i className="me-1 bi bi-people-fill"></i>,
      vissbleOnSmallScrren: true,
    },
    {
      key: "routekey-customerdashboard",
      label: "sidebar.customerdashboard",
      path: "/app/customerdashboard",
      icon: <i className="me-1 bi bi-house-fill"></i>,
      vissbleOnSmallScrren: true,
    },
    {
      key: "routekey-reporting",
      label: "sidebar.reporting",
      path: "/app/reports",
      icon: <i className="me-1 bi bi-briefcase-fill"></i>,
      vissbleOnSmallScrren: true,
    },
    {
      key: "routekey-features",
      label: "sidebar.cookieReport",
      path: "/app/cookieReport",
      subMenu: [
        {
          key: "routekey-cookieScan",
          label: "sidebar.cookieScan",
          path: "/app/cookiescan",
          icon: <i className="me-1 bi bi-briefcase-fill"></i>,
          vissbleOnSmallScrren: true,
        },
        {
          key: "routekey-cookieRepo",
          label: "sidebar.cookieRepo",
          path: "/app/cookierepo",
          icon: <i className="me-1 bi bi-briefcase-fill"></i>,
          vissbleOnSmallScrren: true,
        },
      ],
      icon: <i className="me-1 bi bi-people-fill"></i>,
      vissbleOnSmallScrren: true,
    },
  ],
  partner: [
    {
      key: "routekey-customers",
      label: "sidebar.customers",
      path: "/app/customers",
      icon: <i className="me-1 bi bi-briefcase-fill"></i>,
      vissbleOnSmallScrren: true,
    },
  ],
};

export default Menu;
