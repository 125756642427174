import { createSlice } from "@reduxjs/toolkit";
import {
  fetchTCFConfiguratorSchema,
  addTCFConfiguration,
  getGVLData,
  getTCFConfigurations,
  fetchTCFCMPConfigById,
  deleteTCFConfiguration,
} from "../thunks/tcfConfig";
import { NotificationManager } from "react-notifications";
import _ from "lodash";

const initialState = {
  configurations: [],
  referenceConfig: null,
  filteredConfigurations: [],
  template: null,
  loading: false,
  sdkForScript: null,
  bundleForScript: null,
  schema: null,
};

const tcfConfigSlice = createSlice({
  name: "tcfConfigSlice",
  reducers: {},
  initialState,
  extraReducers: (builder) => {
    // fetch TCF Configurator Schema
    builder.addCase(fetchTCFConfiguratorSchema.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(fetchTCFConfiguratorSchema.fulfilled, (state, action) => {
      state.loading = false;
      state.schema = action.payload;
    });
    builder.addCase(fetchTCFConfiguratorSchema.rejected, (state, action) => {
      NotificationManager.error("Error occured in fetching schema");
      state.loading = false;
    });

    // add TCF Configuration
    builder.addCase(addTCFConfiguration.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(addTCFConfiguration.fulfilled, (state, action) => {
      NotificationManager.success("Configuration added successfully");
      state.loading = false;
    });
    builder.addCase(addTCFConfiguration.rejected, (state, action) => {
      NotificationManager.error("error occured");
      state.loading = false;
    });

    // get GVL Data
    builder.addCase(getGVLData.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(getGVLData.fulfilled, (state, action) => {
      state.loading = false;
      state.gvlData = action.payload;
    });
    builder.addCase(getGVLData.rejected, (state, action) => {
      NotificationManager.error("error occured in getGVLData");
      state.loading = false;
    });

    // get TCF Configurations
    builder.addCase(getTCFConfigurations.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(getTCFConfigurations.fulfilled, (state, action) => {
      state.loading = false;
      state.configurations = action.payload;
      state.filteredConfigurations = action.payload;
    });
    builder.addCase(getTCFConfigurations.rejected, (state, action) => {
      NotificationManager.error("error occured");
      state.loading = false;
      state.error = action.payload;
    });

    // fetch TCF CMP ConfigById
    builder.addCase(fetchTCFCMPConfigById.pending, (state, action) => {
      state.loading = true;
      state.referenceConfig = null;
    });
    builder.addCase(fetchTCFCMPConfigById.fulfilled, (state, action) => {
      state.loading = false;
      state.referenceConfig = JSON.parse(action.payload["config"]);
      state.sdkForScript = action.payload["sdkVersion"];
      state.bundleForScript = action.payload["bundleVersion"];
      state.template = action.payload["template"];
    });
    builder.addCase(fetchTCFCMPConfigById.rejected, (state, action) => {
      NotificationManager.error("error occured");
      state.loading = false;
    });

    // delete TCF Configuration
    builder.addCase(deleteTCFConfiguration.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(deleteTCFConfiguration.fulfilled, (state, action) => {
      // NotificationManager.success("Configuration deleted successfully");
      state.loading = false;
    });
    builder.addCase(deleteTCFConfiguration.rejected, (state, action) => {
      // NotificationManager.error("error occured");
      state.loading = false;
    });
  },
});

export default tcfConfigSlice.reducer;
