import { createSlice } from "@reduxjs/toolkit";
import { sendFeedback } from "../thunks/feedback";
import { NotificationManager } from "react-notifications";

const initialState = {};

const feedbackSlice = createSlice({
  name: "applicationSlice",
  reducers: {},
  initialState,
  extraReducers: (builder) => {
    // send Feedback
    builder.addCase(sendFeedback.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(sendFeedback.fulfilled, (state, action) => {
      NotificationManager.success("Feedback sent successfully");
      state.loading = false;
    });
    builder.addCase(sendFeedback.rejected, (state, action) => {
      NotificationManager.error("Could not send feedback");
      state.loading = false;
    });
  },
});

export default feedbackSlice.reducer;
