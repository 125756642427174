import { createAsyncThunk } from "@reduxjs/toolkit";
import { getAxiosRequest } from "../helpers/index";

export const sendFeedback = createAsyncThunk(
  "feedback/sendFeedback",
  async (model, thunkAPI) => {
    try {
      let res = await getAxiosRequest().post(
        "user/contactus?message=" + model,
        null
      );
      return res.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);
