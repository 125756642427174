import React, { useEffect } from "react";
import { Link } from "react-router-dom";

import AppConfig from "../../constants/appConfig";
import CircularProgress from "@mui/material/CircularProgress";

// PKCE HELPER FUNCTIONS

// Generate a secure random string using the browser crypto functions
function generateRandomString() {
  var array = new Uint32Array(28);
  window.crypto.getRandomValues(array);
  return Array.from(array, (dec) => ("0" + dec.toString(16)).substr(-2)).join(
    ""
  );
}

// Calculate the SHA256 hash of the input text.
// Returns a promise that resolves to an ArrayBuffer
function sha256(plain) {
  const encoder = new TextEncoder();
  const data = encoder.encode(plain);
  return window.crypto.subtle.digest("SHA-256", data);
}

// Base64-urlencodes the input string
function base64urlencode(str) {
  // Convert the ArrayBuffer to string using Uint8 array to convert to what btoa accepts.
  // btoa accepts chars only within ascii 0-255 and base64 encodes them.
  // Then convert the base64 encoded to base64url encoded
  //   (replace + with -, replace / with _, trim trailing =)
  return btoa(String.fromCharCode.apply(null, new Uint8Array(str)))
    .replace(/\+/g, "-")
    .replace(/\//g, "_")
    .replace(/=+$/, "");
}

// Return the base64-urlencoded sha256 hash for the PKCE challenge
async function pkceChallengeFromVerifier(v) {
  let hashed = await sha256(v);
  return base64urlencode(hashed);
}

async function getAuthorized() {
  // Create and store a random "state" value
  var state = generateRandomString();
  localStorage.setItem("pkce_state", state);

  // Create and store a new PKCE code_verifier (the plaintext random secret)
  var code_verifier = generateRandomString();
  localStorage.setItem("pkce_code_verifier", code_verifier);

  // Hash and base64-urlencode the secret to use as the challenge
  var code_challenge = await pkceChallengeFromVerifier(code_verifier);

  // let promise = Promise.resolve(manager.metadataService.getAuthorizationEndpoint());
  // let authorizationEndPoint = await promise;

  // Build the authorization URL
  var url =
    AppConfig.identityConfig.authorize_endpoint +
    "?response_type=code" +
    "&client_id=" +
    encodeURIComponent(AppConfig.identityConfig.client_id) +
    "&state=" +
    encodeURIComponent(state) +
    "&scope=" +
    encodeURIComponent(AppConfig.identityConfig.scope) +
    "&redirect_uri=" +
    encodeURIComponent(AppConfig.identityConfig.redirect_uri) +
    "&code_challenge=" +
    encodeURIComponent(code_challenge) +
    "&code_challenge_method=S256";
  window.location = url;
}

const Landing = (props) => {
  useEffect(() => {
    setTimeout(function () {
      getAuthorized();
    }, 2000);
  }, []);

  return (
    <>
      <div className="container-fluid">
        <div
          className="pos-f-t"
          style={{ marginLeft: "-12px", marginRight: "-12px" }}
        >
          <div className="bg-dark p-3"></div>
        </div>
        <div
          className="jumbotron text-center mt-5"
          style={{ backgroundColor: "#FFFFFF" }}
        >
          <div className="logo">
            <Link to="/" className="logo-normal text-center mr-20">
              <img
                src={require("../../assets/img/gravito_logo.png")}
                className="img-fluid logoimage"
                width="240"
                height="160"
                alt=""
              />
            </Link>
          </div>

          <h1 className="display-4">Welcome to Gravito</h1>
          <p className="lead">
            An unique platform to manage your consent, data and targeting needs.
          </p>
          <p className="lead">
            Start for free by creating your profile with us.
          </p>
          {<CircularProgress size={100} />}
          <p className="loadingmsg">
            Redirecting to sign in / registration screen.
          </p>
          <div
            className="d-flex justify-content-center"
            style={{ flexDirection: "column" }}
          >
            <div className="row">
              <br></br>
            </div>

            <div className="row"></div>
          </div>
          {/* <p className='lead'>
            <Button
              id='authorize'
              secondary
              onClick={() => goToLogin()}
              className='text-white btn-primary btn-round btn-lg'
            >
              Get Started
            </Button>
          </p> */}
        </div>
      </div>
      <div style={{ position: "fixed", bottom: 0, zIndex: -1 }}>
        <img
          src={require("../../assets/illustrations/progress-hd.png")}
          className="img-fluid"
          alt=""
        />
      </div>
    </>
  );
};
export default Landing;
