import { createSlice } from "@reduxjs/toolkit";
import {
  addCustomDomain,
  getCustomDomains,
  deleteCustomDomain,
  validateCustomDomainDns,
  validateCustomDomain,
  renewCustomDomain,
} from "../thunks/firstPartyDomains";
import { NotificationManager } from "react-notifications";
import moment from "moment";
import _ from "lodash";

const initialState = {
  customDomains: [],
  filteredCustomDomains: [],
  customDomain: null,
  search: "",
  action: "",
  loading: false,
  redirect: false,
  selected: null,
  customDomainDns: null,
};

const firstPartyDomainsSlice = createSlice({
  name: "firstPartyDomainsSlice",
  reducers: {
    fillterCustomDomainsByType: (state, action) => {
      if (action.payload === "") {
        state.filteredCustomDomains = state.customDomains;
      } else {
        const filtered = state.customDomains.filter(
          (item) =>
            item.status.toLowerCase().indexOf(action.payload.toLowerCase()) > -1
        );
        state.filteredCustomDomains = filtered;
        state.search = action.payload.toLowerCase();
      }
    },
  },
  initialState,
  extraReducers: (builder) => {
    // add Custom Domain
    builder.addCase(addCustomDomain.pending, (state, action) => {
      state.loading = true;
      state.action = "ADD";
      state.redirect = false;
    });
    builder.addCase(addCustomDomain.fulfilled, (state, action) => {
      NotificationManager.success("Custom domain added successfully.");
      state.loading = false;
      state.customDomain = null;
      state.redirect = true;
    });
    builder.addCase(addCustomDomain.rejected, (state, action) => {
      NotificationManager.error(action.payload.message);
      state.loading = false;
      state.customDomain = null;
    });

    // get Custom Domain
    builder.addCase(getCustomDomains.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(getCustomDomains.fulfilled, (state, action) => {
      state.loading = false;
      state.customDomains = action.payload;
      state.filteredCustomDomains = action.payload;
      state.redirect = false;
    });
    builder.addCase(getCustomDomains.rejected, (state, action) => {
      NotificationManager.error(action.payload.message);
      state.loading = false;
    });

    // delete Custom Domain
    builder.addCase(deleteCustomDomain.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(deleteCustomDomain.fulfilled, (state, action) => {
      NotificationManager.success("Custom domain deleted successfully.");
      state.loading = false;
      state.customDomains = null;
    });
    builder.addCase(deleteCustomDomain.rejected, (state, action) => {
      NotificationManager.error("Could not delete custom domain");
      state.loading = false;
      state.customDomains = null;
    });

    // validate Custom Domain Dns
    builder.addCase(validateCustomDomainDns.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(validateCustomDomainDns.fulfilled, (state, action) => {
      if (action.payload === true) {
        NotificationManager.success("DNS validated successfully.");
      } else {
        NotificationManager.error("Error while, validating DNS.");
      }
      state.loading = false;
    });
    builder.addCase(validateCustomDomainDns.rejected, (state, action) => {
      NotificationManager.error(action.payload);
      state.loading = false;
    });

    // validate Custom Domain
    builder.addCase(validateCustomDomain.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(validateCustomDomain.fulfilled, (state, action) => {
      NotificationManager.success("Custom domain will be validated shortly.");
      state.loading = false;
      state.redirect = true;
    });
    builder.addCase(validateCustomDomain.rejected, (state, action) => {
      NotificationManager.error(action.payload);
      state.loading = false;
      state.redirect = true;
    });

    // renew Custom Domain
    builder.addCase(renewCustomDomain.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(renewCustomDomain.fulfilled, (state, action) => {
      NotificationManager.success("Custom domain renewed successfully.");
      state.loading = false;
      state.redirect = true;
    });
    builder.addCase(renewCustomDomain.rejected, (state, action) => {
      NotificationManager.error(action.payload);
      state.loading = false;
      state.redirect = true;
    });
  },
});

export const { fillterCustomDomainsByType } = firstPartyDomainsSlice.actions;
export default firstPartyDomainsSlice.reducer;
