import { createAsyncThunk } from "@reduxjs/toolkit";
import { getAxiosRequest } from "../helpers/index";

export const getGVLVersions = createAsyncThunk(
  "gvlUpdates/getGVLVersions",
  async (model, thunkAPI) => {
    try {
      let res = await getAxiosRequest().get("/cmpconfigurator/gvlfile");
      return res.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const compareGVLVersions = createAsyncThunk(
  "gvlUpdates/compareGVLVersions",
  async (model, thunkAPI) => {
    try {
      let res = await getAxiosRequest().get(
        `cmpconfigurator/gvlfile/compare?currentFileid=${model.current}&previousFileid=${model.previous}`
      );
      return res.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);
