import { createAsyncThunk } from "@reduxjs/toolkit";
import { getAxiosRequest } from "../helpers/index";

export const getConnectors = createAsyncThunk(
  "connector/getConnectors",
  async (cId, thunkAPI) => {
    try {
      let response = await getAxiosRequest().get(
        `/manage/Connector/Customer/${cId}`
      );
      let connectorTypes = await getAxiosRequest().get(
        "manage/Connector/Types"
      );
      if (response) {
        let types = {};
        connectorTypes.data.map((t) => {
          types[t.id] = t.value;
        });
        let connector = response.data;
        if (connector) {
          connector.config = JSON.parse(connector.config);
        }

        return {
          connector: response.data,
          connectorTypes: types,
        };
      }
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const getConnectorRundata = createAsyncThunk(
  "connector/getConnectorRundata",
  async (model, thunkAPI) => {
    try {
      let response = await getAxiosRequest().get(
        `manage/Connector/RunData?CustomerId=${model.customerID}&FromDateTime=${model.fromDate}&ToDateTime=${model.toDate}`
      );
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const testConnector = createAsyncThunk(
  "connector/testConnector",
  async (id, thunkAPI) => {
    try {
      let response = await getAxiosRequest().get(
        `/manage/Connector/Test/${id}`
      );
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);
export const deleteConnector = createAsyncThunk(
  "connector/deleteConnector",
  async (model, thunkAPI) => {
    try {
      let connector = { ...model, isActive: false };
      let response = await getAxiosRequest().put(
        "/manage/Connector",
        connector
      );

      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const addConnector = createAsyncThunk(
  "connector/addConnector",
  async (model, thunkAPI) => {
    try {
      let response = await getAxiosRequest().post("/manage/Connector", model);

      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);
export const getConnectorTypes = createAsyncThunk(
  "connector/getConnectorTypes",
  async (model, thunkAPI) => {
    try {
      let response = await getAxiosRequest().get("manage/Connector/Types");
      let types = {};
      response.data.map((t) => {
        types[t.id] = t.value;
      });
      return types;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const updateConnector = createAsyncThunk(
  "connector/updateConnector",
  async (model, thunkAPI) => {
    try {
      let response = await getAxiosRequest().put("/manage/Connector", model);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);
