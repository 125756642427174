import { createAsyncThunk } from "@reduxjs/toolkit";
import { getAxiosRequest, getContextObject } from "../helpers/index";

export const getCookieScanHistory = createAsyncThunk(
  "cookieScan/getCookieScanHistory",
  async (model, thunkAPI) => {
    try {
      var currentCustomer = getContextObject()
        ? getContextObject().customerId
        : 0;
      let res = await getAxiosRequest().get(
        `/manage/browserscan/history?customerId=${currentCustomer}`
      );
      return res.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const addCookieDescription = createAsyncThunk(
  "cookieScan/addCookieDescription",
  async (model, thunkAPI) => {
    try {
      var currentCustomer = getContextObject()
        ? getContextObject().customerId
        : 0;
      let res = await getAxiosRequest().post(
        `manage/browserscan/cookierepo/customer/${currentCustomer}`,
        model
      );
      return res.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const getCookiesScanLanguages = createAsyncThunk(
  "cookieScan/getCookiesScanLanguages",
  async (model, thunkAPI) => {
    try {
      let res = await getAxiosRequest().get("manage/browserscan/Language");
      return res.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const scanNonGravitoDomain = createAsyncThunk(
  "cookieScan/scanNonGravitoDomain",
  async (model, thunkAPI) => {
    try {
      var currentCustomer = getContextObject()
        ? getContextObject().customerId
        : 0;
      model.customerId = currentCustomer;
      let res = await getAxiosRequest().post(
        "manage/browserscan/cookie/noncmp",
        model
      );
      return res.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const getCookieScanReport = createAsyncThunk(
  "cookieScan/getCookieScanReport",
  async (model, thunkAPI) => {
    try {
      var currentCustomer = getContextObject()
        ? getContextObject().customerId
        : 0;
      model.customerId = currentCustomer;
      let res = await getAxiosRequest().post(
        "manage/browserscan/cookie",
        model
      );
      return res.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const getCookieDescription = createAsyncThunk(
  "cookieScan/getCookieDescription",
  async (model, thunkAPI) => {
    try {
      var currentCustomer = getContextObject()
        ? getContextObject().customerId
        : 0;
      let res;
      if (currentCustomer !== 0) {
        res = await getAxiosRequest().get(
          `/manage/browserscan/cookierepo/customer/${currentCustomer}`
        );
      } else {
        res = await getAxiosRequest().get(`/manage/browserscan/cookierepo`);
      }
      return res.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const deleteCookieDescription = createAsyncThunk(
  "cookieScan/deleteCookieDescription",
  async (model, thunkAPI) => {
    try {
      var currentCustomer = getContextObject()
        ? getContextObject().customerId
        : 0;
      let res;
      if (currentCustomer !== 0) {
        res = await getAxiosRequest().delete(
          `manage/browserscan/cookierepo/customer/${currentCustomer}?id=${model.id}`
        );
      } else {
        res = await getAxiosRequest().delete(
          `manage/browserscan/cookierepo?id=${model.id}`
        );
      }
      return res.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const updateCookieDescription = createAsyncThunk(
  "cookieScan/updateCookieDescription",
  async (model, thunkAPI) => {
    try {
      var currentCustomer = getContextObject()
        ? getContextObject().customerId
        : 0;
      let res;
      if (currentCustomer !== 0) {
        res = await getAxiosRequest().put(
          `manage/browserscan/cookierepo/customer/${currentCustomer}`,
          model
        );
      } else {
        model.customerId = currentCustomer;
        res = await getAxiosRequest().put(
          `manage/browserscan/cookierepo`,
          model
        );
      }
      return res.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);
