import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import { BLOCKS, INLINES } from "@contentful/rich-text-types";
import React, { useState } from "react";

import { IconButton, Tooltip } from "@mui/material";

const AccordionComponent = ({ title, accordionContent }) => {
  const [isOpen, setOpen] = useState(false);
  return (
    <div
      style={{ border: "2px solid grey", padding: "10px", marginTop: "10px" }}
    >
      <div
        style={{
          cursor: "pointer",
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          height: "15px",
          alignItems: "center",
        }}
        onClick={() => setOpen((state) => !state)}
      >
        <p style={{ marginBottom: "0px", fontWeight: "bold" }}>{title}</p>
        <div className="small">
          <Tooltip
            id="tooltip-icon"
            placement="bottom"
            title={<div>Open document</div>}
          >
            <IconButton
              className="text-danger iconbtn"
              aria-label="Delete Application"
              // onClick={() => setOpen((state) => !state)}
            >
              <i
                className={`ti-angle-${
                  isOpen ? "up" : "down"
                } center deletebutton`}
              ></i>
            </IconButton>
          </Tooltip>
        </div>
      </div>
      <div
        style={{
          display: isOpen ? "flex" : "none",
          marginTop: "10px",
          flexDirection: "column",
        }}
      >
        {documentToReactComponents(accordionContent, options)}
      </div>
    </div>
  );
};

const options = {
  renderNode: {
    [BLOCKS.PARAGRAPH]: (node, children) => (
      <p className={paragraphClass(node)}>{children}</p>
    ),
    [BLOCKS.UL_LIST]: (node, children) => (
      <ul style={{ color: "red" }}>{children}</ul>
    ),
    [INLINES.HYPERLINK]: ({ data }, children) => (
      <a href={data.uri}>{children}</a>
    ),
    [BLOCKS.EMBEDDED_ENTRY]: (node) => {
      const { title, accordionContent } = node.data.target.fields;
      console.log(node.data.target.fields);
      return (
        <AccordionComponent title={title} accordionContent={accordionContent} />
      );
    },
    [BLOCKS.EMBEDDED_ASSET]: (node, children) => {
      // render the EMBEDDED_ASSET as you need

      return (
        <img
          style={{
            width: "80%",
            marginBottom: "20px",
            alignSelf: "center",
            justifyContent: "center",
          }}
          src={`https://${node.data.target.fields.file.url}`}
          alt=""
        />
      );
    },
  },
};

function paragraphClass(node) {
  const className = "odd";
  //alternate logic for 'odd' | 'even'
  return className;
}

const HelpComponent = ({ helpContent }) => {
  if (helpContent.items.length === 0) {
    return (
      <div className="gravitoHelp">
        <div className="pt-5 ps-4 fs-6">No Help Content Found</div>
      </div>
    );
  }
  const { fields } = helpContent.items[0];

  const style = {
    padding: "1.25rem",
    marginTop: "1.25rem",
    display: "flex",
    flexDirection: "column",
    minWidth: "100%",
    overflowY: "scroll",
    overflowX: "hidden",
  };

  return (
    <>
      <div className="gravitoHelp" style={style}>
        <div className="helpToolbar">
          {fields.heading ? fields.heading : fields.title}
        </div>
        <div className="mb-10"></div>
        {documentToReactComponents(fields.content, options)}
      </div>
    </>
  );
};

export default HelpComponent;
