import { createSlice } from "@reduxjs/toolkit";
import { getGVLVersions, compareGVLVersions } from "../thunks/gvlUpdates";
import { NotificationManager } from "react-notifications";
import moment from "moment";
import _ from "lodash";

const initialState = {
  gvlLoading: false,
  gvlVersions: [],
  gvlTitles: [],
  gvlData: [],
  filteredGVLData: [],
  compareModel: null,
  currentLastUpdatedDate: null,
  previousLastUpdatedDate: null,
};

const gvlUpdatesSlice = createSlice({
  name: "gvlUpdatesSlice",
  reducers: {
    filterGVLByStatus: (state, action) => {
      var filters = action.payload;
      var data = [];
      var filterArray = [];
      if (filters.vendoradd) {
        filterArray.push("added");
      }
      if (filters.vendordelete) {
        filterArray.push("deleted");
      }
      if (filters.vendorchange) {
        filterArray.push("changed");
      }
      if (filterArray.length > 0) {
        data = [];
        filterArray.forEach((element) => {
          var filteredData = _.filter(state.gvlData, { status: element });
          filteredData.forEach((dataElement) => {
            data.push(dataElement);
          });
        });
      } else {
        data = state.gvlData;
      }
      state.filteredGVLData = data;
    },

    filterGVLForVendors: (state, action) => {
      var vendors = action.payload.replace(/\s/g, "").split(",");
      var data = [];

      if (vendors.length > 0 && action.payload != "") {
        data = [];
        vendors.forEach((vendor) => {
          state.gvlData.filter((eachElement) => {
            if (eachElement.currentVendor.id === vendor) {
              data.push(eachElement);
            }
          });
        });
      } else {
        data = [...state.gvlData];
      }
      state.filteredGVLData = data;
    },

    clearFilter: (state, action) => {
      state.filteredGVLData = [...state.gvlData];
    },
  },
  initialState,
  extraReducers: (builder) => {
    // get Applications
    builder.addCase(getGVLVersions.pending, (state, action) => {
      state.loading = true;
      state.gvlVersions = [];
    });
    builder.addCase(getGVLVersions.fulfilled, (state, action) => {
      state.loading = false;
      state.gvlVersions = action.payload ? action.payload : [];
    });
    builder.addCase(getGVLVersions.rejected, (state, action) => {
      NotificationManager.error("Error while fetching gvl versions");
      state.loading = false;
      state.gvlVersions = [];
    });

    // compare GVL Versions
    builder.addCase(compareGVLVersions.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(compareGVLVersions.fulfilled, (state, action) => {
      state.loading = false;
      state.gvlData = action.payload.result;
      state.filteredGVLData = action.payload.result;
      state.currentLastUpdatedDate = moment(
        action.payload.currentLastUpdateDate
      ).format("MMMM Do YYYY");
      state.previousLastUpdatedDate = moment(
        action.payload.previousLastUpdateDate
      ).format("MMMM Do YYYY");
    });
    builder.addCase(compareGVLVersions.rejected, (state, action) => {
      state.loading = false;
    });
  },
});

export const { filterGVLByStatus, filterGVLForVendors, clearFilter } =
  gvlUpdatesSlice.actions;
export default gvlUpdatesSlice.reducer;
