import { createSlice } from "@reduxjs/toolkit";
import {
  getApplications,
  deleteApplication,
  updateApplication,
  addApplication,
} from "../thunks/application";
import { NotificationManager } from "react-notifications";

const initialState = {};

const applicationSlice = createSlice({
  name: "applicationSlice",
  reducers: {},
  initialState,
  extraReducers: (builder) => {
    // get Applications
    builder.addCase(getApplications.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(getApplications.fulfilled, (state, action) => {
      state.loading = false;
      state.applications = action.payload.data;
      state.filteredApplications = action.payload.data;
      state.redirect = false;
    });
    builder.addCase(getApplications.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });

    // delete Application
    builder.addCase(deleteApplication.pending, (state, action) => {
      if (action.payload === "") {
        state.error = "Please enter a valid application details";
      } else {
        state.loading = true;
        state.application = action.payload;
        state.action = "DELETE";
      }
    });
    builder.addCase(deleteApplication.fulfilled, (state, action) => {
      state.loading = false;
      state.application = null;
    });
    builder.addCase(deleteApplication.rejected, (state, action) => {
      NotificationManager.error("Could not delete application");
      state.loading = false;
      state.application = null;
    });

    // update Application
    builder.addCase(updateApplication.pending, (state, action) => {
      state.loading = true;
      state.action = "UPDATE";
      state.redirect = false;
    });
    builder.addCase(updateApplication.fulfilled, (state, action) => {
      NotificationManager.success("Application updated successfully");
      state.loading = false;
      state.application = null;
      state.selected = null;
      state.redirect = false;
    });
    builder.addCase(updateApplication.rejected, (state, action) => {
      NotificationManager.error("Could not update application");
      state.loading = false;
      state.application = null;
      state.redirect = true;
    });

    // add Application
    builder.addCase(addApplication.pending, (state, action) => {
      state.loading = true;
      state.action = "ADD";
      state.redirect = false;
    });
    builder.addCase(addApplication.fulfilled, (state, action) => {
      NotificationManager.success("Application added successfully.");
      state.loading = false;
      state.application = null;
      state.redirect = true;
    });
    builder.addCase(addApplication.rejected, (state, action) => {
      NotificationManager.error(action.payload);
      state.loading = false;
      state.application = null;
    });
  },
});

export default applicationSlice.reducer;
