import { createSlice } from "@reduxjs/toolkit";
import { customerRegistration } from "../thunks/registeration";
import NotificationManager from "react-notifications/lib/NotificationManager";

const initialState = {
  customer: null,
  loading: false,
  createdCustomer: null,
  refresh: false,
};

const registrationSlice = createSlice({
  name: "registerationSlice",
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(customerRegistration.pending, (state, action) => {
      return {
        ...state,
        loading: true,
      };
    });
    builder.addCase(customerRegistration.fulfilled, (state, action) => {
      NotificationManager.success("Customer registration is successful");

      return {
        ...state,
        loading: false,
        customer: null,
        createdCustomer: action.payload,
        refresh: true,
      };
    });
    builder.addCase(customerRegistration.rejected, (state, action) => {
      
      NotificationManager.error(
        "There was an error while registering you as customer, please contact gravito support."
      );
      return {
        ...state,
        loading: false,
        customer: null,
        createdCustomer: null,
      };
    });
  },
});

export default registrationSlice.reducer;
