import { createAsyncThunk } from "@reduxjs/toolkit";
import { getAxiosRequest, getContextObject } from "../helpers/index";

export const customerRegistration = createAsyncThunk(
  "registerationThunk/customerRegisteration",
  async (model, thunkAPI) => {
    
    try {
      model.primaryEmail = "change@gravito.net"; // change it after making changes to api
      // Register as a new customer
      let res = await getAxiosRequest().post("/customer", model);
      return res;
    } catch (error) {

      console.log("error", error);
      return thunkAPI.rejectWithValue(error.response.data.error);
    }
  }
);
