import React, { useContext, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";

let AuthContext = React.createContext({});

export const AuthContextProvider = (props) => {
  const [authData, setAuthData] = useState({
    currentCustomer: "",
    currentDomain: "",
    user: "",
    mappedCustomers: [],
  });
  const navigate = useNavigate();

  const login = (data) => {
    setAuthData(data);
    navigate("/app");
  };
  const setData = (data) => {
    setAuthData(data);
  };
  const logout = () => {
    setAuthData(null);
    navigate("/");
  };
  const value = useMemo(() => {
    return {
      data: authData,
      login: login,
      logout: logout,
      setData,
    };
  }, [authData]);
  return (
    <AuthContext.Provider value={value}>{props.children}</AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);
