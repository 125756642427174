import { createSlice } from "@reduxjs/toolkit";
import { create } from "lodash";
import { getRegistrationContent } from "../thunks/help";

const initialState = {
  contentID: null,
  contentData: null,
  contentLoading: false,
  welcomeContent: [],
  registrationContent: [],
  dashboardContent: [],
  tcfHelpContent: [],
};

const helpSlice = createSlice({
  name: "helpSlice",
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getRegistrationContent.pending, (state, action) => {
      return { ...state, contentLoading: true };
    });
    builder.addCase(getRegistrationContent.fulfilled, (state, action) => {
      let content = [];
      content = action.payload;
      return {
        ...state,
        registrationContent: content,
        contentLoading: false,
      };
    });
    builder.addCase(getRegistrationContent.rejected, (state, action) => {
      return { ...state, contentLoading: false, registrationContent: [] };
    });
  },
});

export default helpSlice.reducer;
