import { createAsyncThunk } from "@reduxjs/toolkit";
import { getAxiosRequest } from "../helpers/index";
import axios from "axios";

import localSchema from "../pages/tcfConfig/schema/primary";

export const fetchTCFConfiguratorSchema = createAsyncThunk(
  "tcfConfig/fetchTCFConfiguratorSchema",
  async (model, thunkAPI) => {
    try {
      let res;
      res = await axios.get(
        "https://cdn.gravito.net/configmappers/tcfSchema.json"
      );
      // return res.data;
      return localSchema;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const addTCFConfiguration = createAsyncThunk(
  "tcfConfig/addTCFConfiguration",
  async (model, thunkAPI) => {
    try {
      var config = {};
      config = {
        headers: {
          DOMAIN_KEY: model.domain,
          "content-type": "application/json",
        },
      };

      let res = await getAxiosRequest().put(
        `/cmpconfigurator/tcfconfig`,
        model,
        config
      );
      return res.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const getGVLData = createAsyncThunk(
  "tcfConfig/getGVLData",
  async (model, thunkAPI) => {
    try {
      let res = {};
      // gvl data
      let gvlData = await axios.get(
        "https://cdn.gravito.net/tcf-v3/vendor-list.json"
      );

      // google vendors
      let googleVendorData = await axios.get(
        "https://cdn.gravito.net/tcf-v3/google-vendor-list.json"
      );

      res = {
        ...gvlData.data,
        googleVendors: googleVendorData.data,
      };

      return res;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const getTCFConfigurations = createAsyncThunk(
  "tcfConfig/getTCFConfigurations",
  async (model, thunkAPI) => {
    try {
      var config = {};
      config = {
        headers: { DOMAIN_KEY: model.id },
      };
      let res = await getAxiosRequest().get(
        `/cmpconfigurator/tcfconfig/${model.customerId}`,
        config
      );
      return res.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const fetchTCFCMPConfigById = createAsyncThunk(
  "tcfConfig/fetchTCFCMPConfigById",
  async (model, thunkAPI) => {
    try {
      var config = {};
      config = {
        headers: {
          "content-type": "application/json",
        },
      };
      let res = await getAxiosRequest().get(
        `/cmpconfigurator/${model.id}`,
        config
      );
      return res.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const deleteTCFConfiguration = createAsyncThunk(
  "tcfConfig/deleteTCFConfiguration",
  async (model, thunkAPI) => {
    try {
      let path = model.path.replace("tcf/", "");
      let res = await getAxiosRequest().delete(
        `/cmpconfigurator/TCFconfig/${path}`
      );
      return res.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const deleteTCFConfigurationDraft = createAsyncThunk(
  "tcfConfig/deleteTCFConfigurationDraft",
  async (model, thunkAPI) => {
    try {
      let res = await getAxiosRequest().delete(
        `/cmpconfigurator/TCFconfig/${model.customerId}/draft/${model.id}`
      );
      return res.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const addTCFConfigurationDraft = createAsyncThunk(
  "tcfConfig/addTCFConfigurationDraft",
  async (model, thunkAPI) => {
    try {
      let res = await getAxiosRequest().post(
        `/cmpconfigurator/TCFconfig/draft`,
        model
      );
      return res.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const updateTCFConfigurationDraft = createAsyncThunk(
  "tcfConfig/updateTCFConfigurationDraft",
  async (model, thunkAPI) => {
    try {
      let res = await getAxiosRequest().put(
        `/cmpconfigurator/TCFconfig/draft`,
        model
      );
      return res.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);
