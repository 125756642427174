import { createAsyncThunk } from "@reduxjs/toolkit";
import { getAxiosRequest } from "../helpers/index";

export const addCustomDomain = createAsyncThunk(
  "firstPartyDomains/addCustomDomain",
  async (model, thunkAPI) => {
    try {
      var config = {};
      config = {
        headers: { DOMAIN_KEY: model.currentDomain.id },
      };
      let res = await getAxiosRequest().post(
        `/manage/customer/${model.currentDomain.customerId}/customdomain`,
        model.customDomain,
        config
      );
      return res.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);
export const getCustomDomains = createAsyncThunk(
  "firstPartyDomains/getCustomDomains",
  async (model, thunkAPI) => {
    try {
      var config = {};
      config = {
        headers: { DOMAIN_KEY: model.id },
      };
      let res = await getAxiosRequest().get(
        `/manage/customer/${model.customerId}/customdomain`,
        config
      );
      return res.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const deleteCustomDomain = createAsyncThunk(
  "firstPartyDomains/deleteCustomDomain",
  async (model, thunkAPI) => {
    try {
      var config = {};
      config = {
        headers: { DOMAIN_KEY: model.currentDomain.id },
      };
      let res = await getAxiosRequest().delete(
        `/manage/customer/${model.currentDomain.customerId}/customdomain/${model.customDomain.id}`,
        config
      );
      return res.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const validateCustomDomainDns = createAsyncThunk(
  "firstPartyDomains/validateCustomDomainDns",
  async (model, thunkAPI) => {
    try {
      var config = {};
      config = {
        headers: { DOMAIN_KEY: model.currentDomain.id },
      };
      let res = await getAxiosRequest().get(
        `/manage/customer/${model.currentDomain.customerId}/customdomain/validate/dns/${model.customDomain.domain}`,
        config
      );
      return res.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const validateCustomDomain = createAsyncThunk(
  "firstPartyDomains/validateCustomDomain",
  async (model, thunkAPI) => {
    try {
      var config = {};
      config = {
        headers: { DOMAIN_KEY: model.currentDomain.id },
      };
      model.customDomain.status = "Created";
      let res = await getAxiosRequest().put(
        `/manage/customer/${model.currentDomain.customerId}/customdomain/${model.customDomain.id}/validate`,
        model.customDomain,
        config
      );
      return res.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const renewCustomDomain = createAsyncThunk(
  "firstPartyDomains/renewCustomDomain",
  async (model, thunkAPI) => {
    try {
      var config = {};
      config = {
        headers: { DOMAIN_KEY: model.currentDomain.id },
      };
      let res = await getAxiosRequest().put(
        `/manage/customer/${model.currentDomain.customerId}/customdomain/${model.customDomain.id}/renew`,
        model.customDomain,
        config
      );
      return res.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);
