import { createSlice } from "@reduxjs/toolkit";
import {
  getCookieScanHistory,
  addCookieDescription,
  getCookiesScanLanguages,
  scanNonGravitoDomain,
  getCookieScanReport,
  getCookieDescription,
  deleteCookieDescription,
  updateCookieDescription,
} from "../thunks/cookieScan";
import { NotificationManager } from "react-notifications";

const initialState = {
  loading: false,
  reportData: null,
  reportRecived: false,
  scanHistory: [],
  scanRequestObj: null,
  cookieDescriptions: [],
  currentDescription: null,
  filteredDescriptions: [],
  nonGravitoDomain: null,
  nonGravitoDomainReport: [],
  languages: [],
  redirect: false,
};

const cookieScanSlice = createSlice({
  name: "cookieScanSlice",
  reducers: {
    newScanCookiesReport: (state, action) => {
      state.reportData = null;
      state.reportRecived = false;
    },
  },
  initialState,
  extraReducers: (builder) => {
    // get CookieScan History
    builder.addCase(getCookieScanHistory.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(getCookieScanHistory.fulfilled, (state, action) => {
      state.loading = false;
      state.scanHistory = action.payload;
    });
    builder.addCase(getCookieScanHistory.rejected, (state, action) => {
      state.loading = false;
      state.scanHistory = [];
    });

    // add Cookie Description
    builder.addCase(addCookieDescription.pending, (state, action) => {
      state.loading = true;
      state.currentDescription = action.payload;
    });
    builder.addCase(addCookieDescription.fulfilled, (state, action) => {
      NotificationManager.success("Succesfully added description");
      state.loading = false;
      state.currentDescription = null;
      state.redirect = true;
    });
    builder.addCase(addCookieDescription.rejected, (state, action) => {
      NotificationManager.error("Error occured while add cookie to repo");
      state.loading = false;
      state.currentDescription = null;
    });

    // get Cookies Scan Languages
    builder.addCase(getCookiesScanLanguages.pending, (state, action) => {});
    builder.addCase(getCookiesScanLanguages.fulfilled, (state, action) => {
      state.languages = action.payload;
    });
    builder.addCase(getCookiesScanLanguages.rejected, (state, action) => {
      state.languages = [];
    });

    // scan Non-Gravito Domain
    builder.addCase(scanNonGravitoDomain.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(scanNonGravitoDomain.fulfilled, (state, action) => {
      state.nonGravitoDomainReport = action.payload;
      state.nonGravitoDomain = null;
      state.reportRecived = true;
      state.loading = false;
    });
    builder.addCase(scanNonGravitoDomain.rejected, (state, action) => {
      state.nonGravitoDomainReport = [];
      state.nonGravitoDomain = null;
      state.loading = false;
    });

    // get CookieScan Report
    builder.addCase(getCookieScanReport.pending, (state, action) => {
      state.loading = true;
      state.reportData = null;
      state.reportRecived = false;
    });
    builder.addCase(getCookieScanReport.fulfilled, (state, action) => {
      let reportData = action.payload;
      if (reportData) {
        reportData.beforeAccept = reportData.beforeAcceptCookies;

        reportData.afterAccept = reportData.afterAcceptCookies;
      }
      state.loading = false;
      state.reportData = reportData;
      state.reportRecived = true;
    });
    builder.addCase(getCookieScanReport.rejected, (state, action) => {
      state.reportRecived = false;
      state.loading = false;
    });

    // get Cookie Description
    builder.addCase(getCookieDescription.pending, (state, action) => {
      state.loading = true;
      state.redirect = false;
    });
    builder.addCase(getCookieDescription.fulfilled, (state, action) => {
      state.loading = false;
      state.cookieDescriptions = action.payload;
      state.filteredDescriptions = action.payload;
    });
    builder.addCase(getCookieDescription.rejected, (state, action) => {
      NotificationManager.error("Error occured");
      state.loading = false;
    });

    // delete Cookie Description
    builder.addCase(deleteCookieDescription.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(deleteCookieDescription.fulfilled, (state, action) => {
      NotificationManager.success("Succesfully deleted description");
      state.loading = false;
      state.cookieDescriptions = action.payload;
      state.filteredDescriptions = action.payload;
    });
    builder.addCase(deleteCookieDescription.rejected, (state, action) => {
      NotificationManager.error("Error occured");
      state.loading = false;
    });

    // update Cookie Description
    builder.addCase(updateCookieDescription.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(updateCookieDescription.fulfilled, (state, action) => {
      NotificationManager.success("Succesfully Updated description");
      state.loading = false;
      state.currentDescription = null;
      state.redirect = true;
    });
    builder.addCase(updateCookieDescription.rejected, (state, action) => {
      NotificationManager.error("Error occured");
      state.loading = false;
    });
  },
});

export const { newScanCookiesReport } = cookieScanSlice.actions;

export default cookieScanSlice.reducer;
