import axios from "axios";
import AppConfig from "../constants/appConfig";
import AES from "crypto-js/aes";
import CryptoENC from "crypto-js/enc-utf8";

export function createContextHash(object) {
  var ciphertext = AES.encrypt(JSON.stringify(object), "grvcontext");
  sessionStorage.setItem("context", ciphertext);
}

export function getContextObject() {
  if (sessionStorage.getItem("context")) {
    var _ciphertext = AES.decrypt(
      sessionStorage.getItem("context"),
      "grvcontext"
    );
    return JSON.parse(_ciphertext.toString(CryptoENC));
  } else {
    return null;
  }
}

export const getAxiosRequest = () => {
  var instance = axios.create({
    baseURL: `${AppConfig.apiPath}`,
    withCredentials: true,
  });
  instance.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      // if (error.response && error.response.status === 401) {
      //   //window.location=`${AppConfig.loginUrl}`
      //   // document.location.reload();
      // } else {
      return Promise.reject({ ...error });
      // }
    }
  );

  return instance;
};

export const getIdentityAxiosRequest = () => {
  var instance = axios.create({
    baseURL: `${AppConfig.identityApiPath}`,
    withCredentials: true,
  });
  instance.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      if (error.response.status === 401) {
        //window.location=`${AppConfig.loginUrl}`
        // document.location.reload();
      } else {
        return Promise.reject({ ...error });
      }
    }
  );

  return instance;
};

export const getAxiosRequestWithoutCredentials = () => {
  var instance = axios.create({
    baseURL: `${AppConfig.apiPath}`,
    withCredentials: false,
  });
  instance.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      if (error.response.status === 401) {
        window.location = `${AppConfig.loginUrl}`;
        // document.location.reload();
      } else {
        return Promise.reject({ ...error });
      }
    }
  );

  return instance;
};

export const toPercent = (num, total) => {
  let percentage = ((num / total) * 100).toFixed(2) + "%";
  return percentage == "NaN%" ? "N/A" : percentage;
};

export const keywordsFinder = (value, keywords) => {
  let found = false;
  keywords.forEach((keyword) => {
    if (value.toLowerCase().includes(keyword.toLowerCase())) {
      found = true;
    }
  });
  return found;
};

export const browserDataObjectToArray = (browserData) => {
  let browserDataArray = [];
  for (let browser in browserData) {
    browserDataArray.push({ browser, count: browserData[browser] });
  }
  return browserDataArray;
};

export const deviceStatsCalculator = (browserData) => {
  let keywords = {
    desktop: [
      "Windows",
      "Mac OS X",
      "Linux",
      "Chrome OS",
      "Ubuntu",
      "Fedora",
      "FreeBSD",
      "Debian",
    ],
    bot: ["bot"],
    others: ["Other"],
  };

  let stats = {
    mobile: 0,
    mobilePercent: 0,
    desktop: 0,
    desktopPercent: 0,
    bot: 0,
    botPercent: 0,
    others: 0,
    othersPercent: 0,
    total: 0,
  };

  let data = {};

  browserData.forEach(({ browser, count }) => {
    let [osName, appName] = browser.split(" - ");
    if (appName) {
      // classify by OS
      data[osName] = data[osName]
        ? {
            ...data[osName],
            [appName]: data[osName][appName]
              ? data[osName][appName] + count
              : count,
          }
        : { [appName]: count };
      // can be removed by june 2024
    } else {
      // classify by device type
      if (keywordsFinder(osName, ["Mobile"])) {
        stats.mobile += count;
      } else if (keywordsFinder(osName, ["bot"])) {
        stats.bot += count;
      } else if (keywordsFinder(osName, ["Other"])) {
        stats.others += count;
      } else {
        stats.desktop += count;
      }
    }
    stats.total += count;
  });

  // filter out bot data and return non-bot data
  const botFilter = (appData) => {
    let notBot = 0;
    Object.keys(appData).forEach((appName) => {
      if (keywordsFinder(appName, keywords.bot)) {
        stats.bot += appData[appName];
      } else {
        notBot += appData[appName];
      }
    });
    return notBot;
  };

  // classify OS by device type
  Object.keys(data).forEach((osName) => {
    if (keywordsFinder(osName, keywords.desktop)) {
      stats.desktop += botFilter(data[osName]);
    } else if (keywordsFinder(osName, keywords.others)) {
      stats.others += botFilter(data[osName]);
    } else {
      stats.mobile += botFilter(data[osName]);
    }
  });

  // calculate device percentage
  stats.mobilePercent = toPercent(stats.mobile, stats.total);
  stats.desktopPercent = toPercent(stats.desktop, stats.total);
  stats.botPercent = toPercent(stats.bot, stats.total);
  stats.othersPercent = toPercent(stats.others, stats.total);

  return stats;
};

export const negativeValueHandler = (value) => {
  return value < 0 ? 0 : value;
};
