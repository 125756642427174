import { createSlice } from "@reduxjs/toolkit";
import {
  getCustomerDomains,
  generateDomainKey,
  getDomains,
  requestDomainDeletion,
  testDomainWebhook,
  updateDomain,
  getCustomerDomainGroups,
} from "../thunks/domains";
import { NotificationManager } from "react-notifications";
import _ from "lodash";

const initialState = {
  loading: false,
  domains: null,
  webHookResponse: null,
  webHookTestStatus: null,
  domain: null,
  action: "",
  redirect: false,
  currentDomain: null,
  domainGroups: [],
};

const domainsSlice = createSlice({
  name: "domainsSlice",
  reducers: {},
  initialState,
  extraReducers: (builder) => {
    // get domains list
    builder.addCase(getCustomerDomains.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(getCustomerDomains.fulfilled, (state, action) => {
      state.loading = false;
      state.domains = _.compact(action.payload);
      state.filteredDomains = _.compact(action.payload);
      state.redirect = false;
    });
    builder.addCase(getCustomerDomains.rejected, (state, action) => {
      state.loading = false;
    });

    // generate Domain Key
    builder.addCase(generateDomainKey.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(generateDomainKey.fulfilled, (state, action) => {
      NotificationManager.success(
        "Access Token generated successfully for domain " +
          action.payload.domainName
      );
      state.loading = false;
    });
    builder.addCase(generateDomainKey.rejected, (state, action) => {
      state.loading = false;
    });

    // get domains list
    builder.addCase(getDomains.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(getDomains.fulfilled, (state, action) => {
      state.loading = false;
      state.domains = _.compact(action.payload);
      state.filteredDomains = _.compact(action.payload);
      state.redirect = false;
    });
    builder.addCase(getDomains.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload.message;
    });

    // get domains list
    builder.addCase(requestDomainDeletion.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(requestDomainDeletion.fulfilled, (state, action) => {
      NotificationManager.success(
        "Domain request has been successfully sent to Gravito admin."
      );
      state.loading = false;
      state.domain = null;
    });
    builder.addCase(requestDomainDeletion.rejected, (state, action) => {
      state.loading = false;
      if (action.payload) {
        NotificationManager.error(action.payload.message);
      } else {
        NotificationManager.error(
          "Sorry, we could not process your request for domain deletion."
        );
      }
    });

    // test domain webhook
    builder.addCase(testDomainWebhook.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(testDomainWebhook.fulfilled, (state, action) => {
      var success = true;
      if (action.payload === null) {
        success = false;
      }
      state.loading = false;
      state.webHookResponse = action.payload;
      state.webHookTestStatus = success;
    });
    builder.addCase(testDomainWebhook.rejected, (state, action) => {
      state.loading = false;
    });

    // update domain
    builder.addCase(updateDomain.pending, (state, action) => {
      state.loading = true;
      // var domain = delete action.payload.emailValid;
      // domain = delete action.payload.domainValid;
      state.loading = false;
      state.action = "UPDATE";
      state.redirect = false;
      state.domain = action.payload;
    });
    builder.addCase(updateDomain.fulfilled, (state, action) => {
      var currentDomain = state.domain;
      NotificationManager.success(
        "The domain details have been updated, it will take a little while to propagate these changes across Gravito."
      );
      state.loading = false;
      state.currentDomain = currentDomain;
      state.domain = null;
      state.redirect = false;
    });
    builder.addCase(updateDomain.rejected, (state, action) => {
      NotificationManager.error("Could not update domain");
      state.loading = false;
      state.domain = null;
      state.redirect = true;
    });
    //Domain groups calls

    builder.addCase(getCustomerDomainGroups.pending, (state, action) => {
      state.loading = true;
    });

    builder.addCase(getCustomerDomainGroups.fulfilled, (state, action) => {
      state.loading = false;
      state.domainGroups = action.payload;
    });

    builder.addCase(getCustomerDomainGroups.rejected, (state, action) => {
      state.loading = false;
      state.domainGroups = [];
    });
  },
});

export default domainsSlice.reducer;
