import { createSlice } from "@reduxjs/toolkit";
import { getDomainUsers, getFirstpartyDomains, logOff } from "../thunks/auth";
import AppConfig from "../constants/appConfig";

const initialState = {
  user: null,
  email: "",
  loading: false,
  error: "",
  socialuser: null, // This will be used for the social login
  deleteUser: null,
  userDeleted: false,
  userDomains: [],
  currentDomain: "",
  isAdmin: false,
  customerAdmin: false,
  serverState: null,
  isNormalUser: false,
  firstPartyDomains: [],
  userCustomerDomain: null,
  mappedCustomers: [],
  mappedDomains: [],
  currentCustomer: "",
  isPartner: false,
};

const authSlice = createSlice({
  name: "authSlice",
  reducers: {},
  initialState,
  extraReducers: (builder) => {
    // get domains list
    // builder.addCase(authenticateUser.pending, (state, action) => {
    //   state.loading = true;
    // });
    // builder.addCase(authenticateUser.fulfilled, (state, action) => {
    //   var isGravitoAdmin = false;
    //   var isAdminUser = false;
    //   var isPartner = false;
    //   // check if the user accessing the admin portal is admin or domain admin user.
    //   isAdminUser =
    //     action.payload.data.roles.includes("ADMIN") ||
    //     action.payload.data.roles.includes("DOMAIN_ADMIN") ||
    //     action.payload.data.roles.includes("PARTNER");
    //   if (action.payload.data.roles) {
    //     isGravitoAdmin = action.payload.data.roles.includes("ADMIN");
    //   }
    //   if (action.payload.data.roles) {
    //     isPartner = action.payload.data.roles.includes("PARTNER");
    //   }
    //   // get details about users customership and then populate it for showing the customer and domain list.
    //   var mappedCustomers = [];
    //   var mappedDomains = [];
    //   let userCustomerDomainMapping = null;
    //   // if user is not a gravito admin then resolve the customer data
    //   if (!isGravitoAdmin && action.payload.data.customerDomainList) {
    //     userCustomerDomainMapping =
    //       action.payload.data.customerDomainList[0].CustomerMaster;
    //     const customerList = [
    //       ...new Map(
    //         userCustomerDomainMapping.map((item) => [item["CustomerId"], item])
    //       ).values(),
    //     ];
    //     if (customerList.length > 0) {
    //       customerList.forEach((element) => {
    //         mappedCustomers.push({
    //           name: element.CustomerName,
    //           id: element.CustomerId,
    //           useCM: element.UseContentManagementFeature,
    //         });
    //       });
    //       customerList[0].Domain.forEach((domain) => {
    //         var item = {
    //           domain: domain.DomainName,
    //           id: domain.DomainDocumentId,
    //           customerId: customerList[0].CustomerId,
    //         };
    //         mappedDomains.push(item);
    //       });
    //     }
    //   }
    //   // check if there are any mapped customers, they will not be there for new users.
    //   if (mappedCustomers[0]) {
    //     var context = {
    //       isPartner: isPartner,
    //       customerId: mappedCustomers[0].id,
    //     };
    //     // create hash of the context and store it in sessionStorage
    //     var ciphertext = AES.encrypt(JSON.stringify(context), "grvcontext");
    //     sessionStorage.setItem("context", ciphertext);
    //   }
    //   // return another state param isNormalUser to show the registration routes...
    //   return {
    //     ...state,
    //     loading: false,
    //     serverState: true,
    //     user: action.payload.data,
    //     isAdmin: isGravitoAdmin,
    //     isNormalUser: !isAdminUser,
    //     isPartner: isPartner,
    //     userCustomerDomain: userCustomerDomainMapping,
    //     mappedCustomers: mappedCustomers,
    //     mappedDomains: mappedDomains,
    //     currentDomain: mappedDomains.length > 0 ? mappedDomains[0] : "",
    //     currentCustomer: mappedCustomers.length > 0 ? mappedCustomers[0] : "",
    //   };
    // });
    // builder.addCase(authenticateUser.rejected, (state, action) => {
    //   state.loading = false;
    //   state.serverState = false;
    // });

    // add Application
    builder.addCase(getDomainUsers.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(getDomainUsers.fulfilled, (state, action) => {
      sessionStorage.setItem("domainUsers", JSON.stringify(action.payload));
      state.loading = false;
    });
    builder.addCase(getDomainUsers.rejected, (state, action) => {
      state.loading = false;
    });

    // get Firstparty Domains
    builder.addCase(getFirstpartyDomains.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(getFirstpartyDomains.fulfilled, (state, action) => {
      state.firstPartyDomains = action.payload;
      state.loading = false;
    });
    builder.addCase(getFirstpartyDomains.rejected, (state, action) => {
      state.loading = false;
    });

    // log-out
    builder.addCase(logOff.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(logOff.fulfilled, (state, action) => {
      sessionStorage.clear();
      localStorage.clear();
      document.cookie =
        "gravitoassist=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
      // setTimeout(() => {
      //   window.location = `${AppConfig.appUrl}`;
      // }, 500);
      state.loading = false;
    });
    builder.addCase(logOff.rejected, (state, action) => {
      state.loading = false;
    });
  },
});

export default authSlice.reducer;
