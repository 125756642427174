import { createAsyncThunk } from "@reduxjs/toolkit";
const contentful = require("contentful");

const client = contentful.createClient({
  space: "vbdao2w6kspn",
  accessToken: "4bUAGxf7ksN3zxWHd-8l9HeYs8rsF14GyDEmq-wbV38",
});

export const getFeatureHelp = createAsyncThunk(
  "help/getFeatureHelp",
  async (id, thunkAPI) => {
    try {
      const response = await client.getEntries({
        content_type: "featureHelp",
        limit: 2,
        include: 10,
        "fields.title": id,
      });

      return response;
    } catch (error) {
      console.error("error", error);
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const getRegistrationContent = createAsyncThunk(
  "help/getFeatureHelp",
  async (id, thunkAPI) => {
    try {
      const response = await client.getEntries({
        content_type: "registration",
        limit: 10,
        include: 10,
      });

      return response;
    } catch (error) {
      console.error("error", error);
      return thunkAPI.rejectWithValue(error);
    }
  }
);
