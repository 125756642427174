import React, { useEffect, useState } from "react";
import { Provider } from "react-redux";
import {
  HashRouter as Router,
  Navigate,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from "react-router-dom";
import { ThemeContextProvider } from "../contexts/theme";
import { useDispatch } from "react-redux";
import { authenticateUser } from "../thunks/auth";
import Store from "../store";
import MainApp from "./mainapp";
import { AuthContextProvider, useAuth } from "../contexts/auth";
import Landing from "../pages/landing";
import I18n from "../locales";
import CircularProgress from "@mui/material/CircularProgress";
import Register from "../pages/registration/index";

// const Register = React.lazy(() => import("../pages/registration/index"));

// const Register = () => {
//   return <div>Register</div>;
// };

const Spinner = () => {
  return <CircularProgress />;
};
const RootContainer = (props) => {
  const { data, login, logout } = useAuth();
  const [loading, setLoading] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    // authenticate user if its not a invite confirmation link
    onUseEffect();
  }, []);
  const onUseEffect = async () => {
    if (location.pathname !== "/magiclinkinviteconfirmation") {
      const search = window.location.search;
      const params = new URLSearchParams(search);
      const authorization_code = params.get("code");
      const authorization_session_state = params.get("session_state");
      if (authorization_code) {
        localStorage.setItem("authorization_code", authorization_code);
      }
      if (authorization_session_state) {
        localStorage.setItem(
          "authorization_session_state",
          authorization_session_state
        );
        window.location.href = window.location.href.split("?")[0];
      }

      if (authorization_code) {
        let res = await dispatch(authenticateUser()).unwrap();
        console.log("res", res);
        login(res);
        navigate("/app");
      }
    }
  };
  useEffect(() => {
    checkAuth();
  }, []);
  const checkAuth = async () => {
    setLoading(true);
    try {
      let res = await dispatch(authenticateUser()).unwrap();
      setLoading(false);

      login(res);
      navigate("/app");
    } catch (error) {
      logout();
      navigate("/landing");
      setLoading(false);
    }
  };
  return loading ? (
    <div>
      <CircularProgress />
    </div>
  ) : (
    <Routes>
      <Route path={`/app/*`} element={<MainApp />}></Route>
      <Route path={`/`} element={<Navigate replace to="/app" />} />
      <Route path="/" element={<Spinner />} />
      <Route path={"/landing"} element={<Landing />} />
      <Route path={"/register"} element={<Register />} />
    </Routes>
  );
};

const RootConatainerWrapper = (props) => {
  return (
    <Provider store={Store}>
      <ThemeContextProvider Theme={"light"}>
        <Router>
          <AuthContextProvider>
            <RootContainer />
          </AuthContextProvider>
        </Router>
      </ThemeContextProvider>
    </Provider>
  );
};

export default RootConatainerWrapper;
