import react, { useState } from "react";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import Button from "@mui/material/Button";

const LoadingOverlay = () => {
  //   const [open, setOpen] = React.useState(false);
  //   const handleClose = () => {
  //     setOpen(false);
  //   };
  //   const handleOpen = () => {
  //     setOpen(true);
  //   };

  return (
    <Backdrop
      sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
      open={true}
    >
      <CircularProgress color="primary" />
    </Backdrop>
  );
};

export default LoadingOverlay;
