import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  getAxiosRequest,
  createContextHash,
  getIdentityAxiosRequest,
} from "../helpers/index";

export const authenticateUser = createAsyncThunk(
  "auth/authenticateUser",
  async (model, thunkAPI) => {
    try {
      if (localStorage.getItem("counter") === null) {
        localStorage.setItem("counter", 0);
      }
      let res = await getAxiosRequest().get("/account/authenticate");
      let customerAdminStatus = await thunkAPI
        .dispatch(checkCustomerAdminStatus())
        .unwrap();
      let data = res.data;
      var isGravitoAdmin = false;
      var isAdminUser = false;
      var isPartner = false;
      let isSelfServiceCustomer = true;
      // check if the user accessing the admin portal is admin or domain admin user.
      isAdminUser =
        data.roles.includes("ADMIN") ||
        data.roles.includes("DOMAIN_ADMIN") ||
        data.roles.includes("PARTNER");

      if (data.roles) {
        isGravitoAdmin = data.roles.includes("ADMIN");
      }

      if (isGravitoAdmin) {
        window.location.href = "https://admin.gravito.net/#/";
      }

      if (data.roles) {
        isPartner = data.roles.includes("PARTNER");
      }

      // get details about users customership and then populate it for showing the customer and domain list.
      var mappedCustomers = [];
      var mappedDomains = [];
      let userCustomerDomainMapping = null;
      let userId = null;
      // if user is not a gravito admin then resolve the customer data
      if (!isGravitoAdmin && data.customerDomainList) {
        userCustomerDomainMapping = data.customerDomainList[0].CustomerMaster;
        userId = data.customerDomainList[0].UserId;
        const customerList = [
          ...new Map(
            userCustomerDomainMapping.map((item) => [item["CustomerId"], item])
          ).values(),
        ];

        if (customerList.length > 0) {
          customerList.forEach((element) => {
            isSelfServiceCustomer = !element.EnterpriseCustomer;
            mappedCustomers.push({
              name: element.CustomerName,
              id: element.CustomerId,
              useCM: element.UseContentManagementFeature,
              CustomerUniqueId: element.CustomerUniqueId,
              isSelfServiceCustomer: isSelfServiceCustomer,
            });
          });
          customerList[0].Domain.forEach((domain) => {
            var item = {
              domain: domain.DomainName,
              id: domain.DomainDocumentId,
              customerId: customerList[0].CustomerId,
              CustomerUniqueId: customerList[0].CustomerUniqueId,
            };
            mappedDomains.push(item);
          });
        }
      }

      // check if there are any mapped customers, they will not be there for new users.
      if (mappedCustomers[0]) {
        var context = {
          isPartner: isPartner,
          isSelfServiceCustomer: isSelfServiceCustomer,
          customerId: mappedCustomers[0].id,
        };
        // create hash of the context and store it in sessionStorage
        createContextHash(context);
      }
      let mappedRes = {
        loading: false,
        serverState: true,
        user: data,
        isAdmin: isGravitoAdmin,
        isNormalUser: !isAdminUser,
        customerAdmin: customerAdminStatus,
        isPartner: isPartner,
        userCustomerDomain: userCustomerDomainMapping,
        mappedCustomers: mappedCustomers,
        mappedDomains: mappedDomains,
        currentDomain: mappedDomains.length > 0 ? mappedDomains[0] : "",
        currentCustomer: mappedCustomers.length > 0 ? mappedCustomers[0] : "",
        userId,
      };
      return mappedRes;
    } catch (error) {
      const counter = parseInt(localStorage.getItem("counter"));
      if (error.response.status === 401 && counter < 4) {
        localStorage.setItem("counter", counter + 1);
      } else {
        thunkAPI.dispatch(logOff());
        return thunkAPI.rejectWithValue(error);
      }
    }
  }
);

export const checkCustomerAdminStatus = createAsyncThunk(
  "auth/checkCustomerAdminStatus",
  async (model, thunkAPI) => {
    try {
      let res = await getAxiosRequest().get("/user/customeradmin");
      return res.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const getDomainUsers = createAsyncThunk(
  "auth/getDomainUsers",
  async (model, thunkAPI) => {
    try {
      var config = {};
      config = {
        headers: { DOMAIN_KEY: model },
      };
      let res = await getAxiosRequest().get("/manage/domain/users", config);
      return res.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const getFirstpartyDomains = createAsyncThunk(
  "auth/getFirstpartyDomains",
  async (model, thunkAPI) => {
    try {
      let res = await getAxiosRequest().get(
        `/manage/customer/${model}/customdomain/status/validated`
      );
      return res.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const logOff = createAsyncThunk(
  "auth/logOff",
  async (model, thunkAPI) => {
    try {
      let res = await getIdentityAxiosRequest().post("/account/logoff", {});
      localStorage.clear();
      return res.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);
