import { createSlice } from "@reduxjs/toolkit";
import {
  getConnectors,
  getConnectorRundata,
  testConnector,
  deleteConnector,
  addConnector,
  getConnectorTypes,
  updateConnector,
} from "../thunks/connector";
import { NotificationManager } from "react-notifications";

const initState = {
  loading: false,
  connectorID: null,
  connector: null,
  connectorTypes: {},
  redirect: false,
  deleteType: null,
  querryData: {},
  runData: null,
};

const ConnectorSlice = createSlice({
  name: "ConnectorSlice",
  initialState: initState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getConnectors.fulfilled, (state, action) => {
      state.connector = action.payload.connector;
      state.connectorTypes = action.payload.connectorTypes;
    });
    builder.addCase(getConnectors.rejected, (state, action) => {
      state.connector = null;
    });
    builder.addCase(getConnectorRundata.fulfilled, (state, action) => {
      state.runData = action.payload;
    });
    builder.addCase(getConnectorRundata.rejected, (state, action) => {
      NotificationManager.error("Something went wrong");
    });

    builder.addCase(testConnector.fulfilled, (state, action) => {
      let result = action.payload;
      if (!result.isSuccessful) {
        NotificationManager.error(result.errorMessage);
      }
      NotificationManager.success("connector is working fine");
    });
    builder.addCase(testConnector.rejected, (state, action) => {
      NotificationManager.error(action.payload.message);
    });
    builder.addCase(deleteConnector.fulfilled, (state, action) => {
      state.deleteType = null;
      state.connectorID = null;
      state.connector = null;
      state.connectorToDelete = null;
    });
    builder.addCase(deleteConnector.rejected, (state, action) => {
      NotificationManager.error("Something went wrong");
    });
    builder.addCase(addConnector.fulfilled, (state, action) => {});
    builder.addCase(addConnector.rejected, (state, action) => {
      NotificationManager.error("Something went wrong");
    });
    builder.addCase(getConnectorTypes.fulfilled, (state, action) => {
      state.connectorTypes = action.payload;
    });

    builder.addCase(getConnectorTypes.rejected, (state, action) => {
      NotificationManager.error("Something went wrong");
    });

    builder.addCase(updateConnector.fulfilled, (state, action) => {});
    builder.addCase(updateConnector.rejected, (state, action) => {
      NotificationManager.error("Something went wrong");
    });

    //end
  },
});

export default ConnectorSlice.reducer;
