import { createSlice, current } from "@reduxjs/toolkit";
import {
  getCustomerList,
  getCustomerConsumerReport,
  getCustomerConsentReport,
  getDomainLedgerReport,
  getDomainEventReport,
} from "../thunks/reporting";
import _ from "lodash";
import moment from "moment";

const INIT_STATE = {
  consentdata: [],
  filteredConsentdata: [],
  consumerdata: [],
  eventsdata: [],
  ledgerdata: [],
  channelCount: [],
  audienceCount: 0,
  customers: [],
  selectedCustomer: null,
  selectedCustomerDomain: null,
  loading: false,
  payment: null,
  refundTransaction: null,
  activityCosts: null,
  contactCosts: null,
  consentChecks: null,
  domainReport: null,
  selectedCustomerDomainForEvent: null,
  filterModel: {
    type: "hour",
    text: "Last 12 hours",
    value: 12,
  },
  reportType: null,
  profilesStats: {
    new: 0,
    revisits: 0,
    updates: 0,
  },
  domainStats: [],
  browserStats: [],
  noDataStatus: false,
  consentFilterDays: 1,
  consentFilterDomain: null,
};
const ReportingSlice = createSlice({
  name: "reportingslice",
  initialState: INIT_STATE,
  reducers: {
    filterCustomerConsentReport(state, action) {
      const currentState = current(state);
      let filterDomains = action.payload;
      let data = null;
      if (filterDomains.length > 0) {
        data = _.filter([...currentState.consentdata], function (o) {
          return filterDomains.includes(o.domain);
        });
      } else {
        data = currentState.consentdata;
      }

      return {
        ...state,
        loading: false,
        error: action.payload,
        filteredConsentdata: data,
        consentFilterDomain: filterDomains,
      };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getCustomerList.pending, (state, action) => {});
    builder.addCase(getCustomerList.fulfilled, (state, action) => {
      state.customers = action.payload;
    });
    builder.addCase(getCustomerList.rejected, (state, action) => {});

    builder.addCase(getCustomerConsumerReport.pending, (state, action) => {});
    builder.addCase(getCustomerConsumerReport.fulfilled, (state, action) => {
      let totalProfilesCreated = 0;
      let totalProfilesFetched = 0;
      let totalProfilesUpdated = 0;
      let totalmpProfilesCreated = 0;
      let totalmpProfilesFetched = 0;
      let totalmpProfilesUpdated = 0;
      let totalmpProfilesMerged = 0;
      let totalProfilesCreatedForTrends = 0;
      let totalProfilesFetchedForTrends = 0;
      let totalProfilesUpdatedForTrends = 0;
      let totalmpProfilesCreatedForTrends = 0;
      let totalmpProfilesFetchedForTrends = 0;
      let totalmpProfilesUpdatedForTrends = 0;
      let totalmpProfilesMergedForTrends = 0;
      let domainsData = [];
      let browserData = [];
      let newProfiles = [];
      let revisits = [];
      let updates = [];

      // Micro profile details
      let mpCreated = [];
      let mpFetched = [];
      let mpUpdated = [];
      let mpMerged = [];
      var dashboardData = action.payload;

      // if the data is returned by the API
      if (dashboardData) {
        // loop through data returned for the given date range.
        dashboardData.forEach((item) => {
          // loop through domainCounts array
          if (item.domainCounts.length > 0) {
            item.domainCounts.forEach((element) => {
              // code for getting the trends Data

              if (element.countByEvent.opProfileCreated) {
                totalProfilesCreatedForTrends =
                  totalProfilesCreatedForTrends +
                  element.countByEvent.opProfileCreated;
              }
              if (element.countByEvent.opProfileFetched) {
                totalProfilesFetchedForTrends =
                  totalProfilesFetchedForTrends +
                  element.countByEvent.opProfileFetched;
              }
              if (element.countByEvent.opProfileUpdated) {
                totalProfilesUpdatedForTrends =
                  totalProfilesUpdatedForTrends +
                  element.countByEvent.opProfileUpdated;
              }

              // new change for microprofile
              if (element.countByEvent.mpProfileCreated) {
                totalmpProfilesCreatedForTrends =
                  totalmpProfilesCreatedForTrends +
                  element.countByEvent.mpProfileCreated;
              }
              if (element.countByEvent.mpProfileFetched) {
                totalmpProfilesFetchedForTrends =
                  totalmpProfilesFetchedForTrends +
                  element.countByEvent.mpProfileFetched;
              }
              if (element.countByEvent.mpProfileUpdated) {
                totalmpProfilesUpdatedForTrends =
                  totalmpProfilesUpdatedForTrends +
                  element.countByEvent.mpProfileUpdated;
              }

              if (element.countByEvent.mpProfileMerged) {
                totalmpProfilesMergedForTrends =
                  totalmpProfilesMergedForTrends +
                  element.countByEvent.mpProfileMerged;
              }

              // push the element into domains data array after checking if an entry exists in the array
              // if entry exisits then add the new counts to previous record
              if (
                _.filter(domainsData, { domain: element.domain }).length > 0
              ) {
                var currentDomainData = _.find(domainsData, {
                  domain: element.domain,
                });
                // count by events items first
                if (element.countByEvent.opProfileCreated) {
                  currentDomainData.countByEvent.opProfileCreated
                    ? (currentDomainData.countByEvent.opProfileCreated =
                        currentDomainData.countByEvent.opProfileCreated +
                        element.countByEvent.opProfileCreated)
                    : (currentDomainData.countByEvent.opProfileCreated =
                        element.countByEvent.opProfileCreated);
                }
                if (element.countByEvent.opProfileFetched) {
                  currentDomainData.countByEvent.opProfileFetched
                    ? (currentDomainData.countByEvent.opProfileFetched =
                        currentDomainData.countByEvent.opProfileFetched +
                        element.countByEvent.opProfileFetched)
                    : (currentDomainData.countByEvent.opProfileFetched =
                        element.countByEvent.opProfileFetched);
                }
                if (element.countByEvent.opProfileUpdated) {
                  currentDomainData.countByEvent.opProfileUpdated
                    ? (currentDomainData.countByEvent.opProfileUpdated =
                        currentDomainData.countByEvent.opProfileUpdated +
                        element.countByEvent.opProfileUpdated)
                    : (currentDomainData.countByEvent.opProfileUpdated =
                        element.countByEvent.opProfileUpdated);
                }

                // change for microprofiles

                if (element.countByEvent.mpProfileCreated) {
                  currentDomainData.countByEvent.mpProfileCreated
                    ? (currentDomainData.countByEvent.mpProfileCreated =
                        currentDomainData.countByEvent.mpProfileCreated +
                        element.countByEvent.mpProfileCreated)
                    : (currentDomainData.countByEvent.mpProfileCreated =
                        element.countByEvent.mpProfileCreated);
                }
                if (element.countByEvent.mpProfileFetched) {
                  currentDomainData.countByEvent.mpProfileFetched
                    ? (currentDomainData.countByEvent.mpProfileFetched =
                        currentDomainData.countByEvent.mpProfileFetched +
                        element.countByEvent.mpProfileFetched)
                    : (currentDomainData.countByEvent.mpProfileFetched =
                        element.countByEvent.mpProfileFetched);
                }

                if (element.countByEvent.mpProfileUpdated) {
                  currentDomainData.countByEvent.mpProfileUpdated
                    ? (currentDomainData.countByEvent.mpProfileUpdated =
                        currentDomainData.countByEvent.mpProfileUpdated +
                        element.countByEvent.mpProfileUpdated)
                    : (currentDomainData.countByEvent.mpProfileUpdated =
                        element.countByEvent.mpProfileUpdated);
                }

                if (element.countByEvent.mpProfileMerged) {
                  currentDomainData.countByEvent.mpProfileMerged
                    ? (currentDomainData.countByEvent.mpProfileMerged =
                        currentDomainData.countByEvent.mpProfileMerged +
                        element.countByEvent.mpProfileMerged)
                    : (currentDomainData.countByEvent.mpProfileMerged =
                        element.countByEvent.mpProfileMerged);
                }

                // countByBrowsers
                if (element.countByBrowser) {
                  // loop through browser entries
                  // if it exists then add to earlier counts else add a new entry
                  for (var key in element.countByBrowser) {
                    if (element.countByBrowser.hasOwnProperty(key)) {
                      // if browser entry already exists in the list then
                      var prevEntry = _.find(browserData, {
                        browser: key,
                      });
                      if (prevEntry) {
                        prevEntry.count =
                          prevEntry.count + element.countByBrowser[key];
                      } else {
                        var item = {
                          browser: key,
                          count: element.countByBrowser[key],
                        };
                        browserData.push(item);
                      }
                    }
                    let domainData = _.find(domainsData, {
                      domain: element.domain,
                    });
                    if (domainData.countByBrowser.hasOwnProperty(key)) {
                      domainData.countByBrowser[key] =
                        domainData.countByBrowser[key] +
                        element.countByBrowser[key];
                    } else {
                      domainData.countByBrowser[key] =
                        element.countByBrowser[key];
                    }
                  }
                }
              }
              // else add the new entry
              else {
                for (var key in element.countByBrowser) {
                  if (element.countByBrowser.hasOwnProperty(key)) {
                    // if browser entry already exists in the list then
                    var prevEntry = _.find(browserData, { browser: key });
                    if (prevEntry) {
                      prevEntry.count =
                        prevEntry.count + element.countByBrowser[key];
                    } else {
                      var item = {
                        browser: key,
                        count: element.countByBrowser[key],
                      };
                      browserData.push(item);
                    }
                  }
                }
                domainsData.push(element);
              }

              // check if the profilefetched event is returned
              if (element.countByEvent.opProfileFetched) {
                totalProfilesFetched =
                  totalProfilesFetched + element.countByEvent.opProfileFetched;
              }
              // check if the profileCreated event is returned
              if (element.countByEvent.opProfileCreated) {
                totalProfilesCreated =
                  totalProfilesCreated + element.countByEvent.opProfileCreated;
              }
              // check if the profileUpdated event is returned
              if (element.countByEvent.opProfileUpdated) {
                totalProfilesUpdated =
                  totalProfilesUpdated + element.countByEvent.opProfileUpdated;
              }

              // change for microprofiles
              // check if the mpprofilefetched event is returned
              if (element.countByEvent.mpProfileFetched) {
                totalmpProfilesFetched =
                  totalmpProfilesFetched +
                  element.countByEvent.mpProfileFetched;
              }
              // check if the mpprofileCreated event is returned
              if (element.countByEvent.mpProfileCreated) {
                totalmpProfilesCreated =
                  totalmpProfilesCreated +
                  element.countByEvent.mpProfileCreated;
              }

              // check if the mpprofileUpdated event is returned
              if (element.countByEvent.mpProfileUpdated) {
                totalmpProfilesUpdated =
                  totalmpProfilesUpdated +
                  element.countByEvent.mpProfileUpdated;
              }

              // check if the mpProfileMerged event is returned
              if (element.countByEvent.mpProfileMerged) {
                totalmpProfilesMerged =
                  totalmpProfilesMerged + element.countByEvent.mpProfileMerged;
              }
            });

            newProfiles.push(totalProfilesCreatedForTrends);
            revisits.push(totalProfilesFetchedForTrends);
            updates.push(totalProfilesUpdatedForTrends);
            mpCreated.push(totalmpProfilesCreatedForTrends);
            mpFetched.push(totalmpProfilesFetchedForTrends);
            mpUpdated.push(totalmpProfilesUpdatedForTrends);
            mpMerged.push(totalmpProfilesMergedForTrends);

            totalProfilesCreatedForTrends = 0;
            totalProfilesFetchedForTrends = 0;
            totalProfilesUpdatedForTrends = 0;
            //changes for microprofile
            totalmpProfilesCreatedForTrends = 0;
            totalmpProfilesFetchedForTrends = 0;
            totalmpProfilesUpdatedForTrends = 0;
            totalmpProfilesMergedForTrends = 0;
          } else {
            newProfiles.push(totalProfilesCreatedForTrends);
            revisits.push(totalProfilesFetchedForTrends);
            updates.push(totalProfilesUpdatedForTrends);
            mpCreated.push(totalmpProfilesCreatedForTrends);
            mpFetched.push(totalmpProfilesFetchedForTrends);
            mpUpdated.push(totalmpProfilesUpdatedForTrends);
            mpMerged.push(totalmpProfilesMergedForTrends);
          }
        });
      }

      // Sort domain data in descending order.
      domainsData
        .sort(
          (x, y) =>
            (x.countByEvent.opProfileFetched
              ? x.countByEvent.opProfileFetched
              : 0 + x.countByEvent.opProfileCreated
              ? x.countByEvent.opProfileCreated
              : 0) -
            (y.countByEvent.opProfileFetched
              ? y.countByEvent.opProfileFetched
              : 0 + y.countByEvent.opProfileCreated
              ? y.countByEvent.opProfileCreated
              : 0)
        )
        .reverse();

      var profilesStats = {
        new: totalProfilesCreated,
        revisits: totalProfilesFetched,
        updates: totalProfilesUpdated,
        mpCreated: totalmpProfilesCreated,
        mpFetched: totalmpProfilesFetched,
        mpUpdated: totalmpProfilesUpdated,
        mpMerged: totalmpProfilesMerged,
      };

      browserData.sort((x, y) => x.count - y.count).reverse();

      state.domainStats = domainsData;
      state.profilesStats = profilesStats;
      state.browserStats = browserData;
      state.noDataStatus = domainsData.length === 0 ? true : false;
      state.consumerdata = domainsData;
    });

    builder.addCase(getCustomerConsentReport.pending, (state, action) => {});

    builder.addCase(getCustomerConsentReport.fulfilled, (state, action) => {
      let days = [];
      // CMP Events
      let cmpDomainsData = [];

      let totalcmpLoaded = 0;
      let totalcmpLayer1Visible = 0;
      let totalcmpLayer2Visible = 0;
      let totalcmpLayer1OptinAll = 0;
      let totalcmpLayer2OptinAll = 0;
      let totalcmpLayer2OptinSelected = 0;
      let totalcmpPreviousOption = 0;
      let totalcmpLayer1OptoutAll = 0;
      let totalcmpLayer2OptoutAll = 0;
      let totalcmpOutdated = 0;
      let totalcmpConsentNotSet = 0;
      let totalLayer1ShowSettings = 0;

      var eventsData = action.payload;

      // if cmp events are returned
      var cmpInfo = [];
      // if the data is returned by the API
      if (eventsData) {
        days = [];
        // loop through data returned for the given date range.
        eventsData.forEach((item) => {
          // loop through domainCounts array
          days.push(moment(item.reportDay).format("DD MMM"));

          // create a structure for the line charts to be shown in cards.
          var cmpDetails = {
            day: moment(item.reportDay).format("DD MMM"),
            acceptAll: 0,
            acceptSelected: 0,
            rejectAll: 0,
            revisits: 0,
          };

          if (item.domainCounts.length > 0) {
            item.domainCounts.forEach((element) => {
              // push the element into domains data array after checking if an entry exists in the array
              // if entry exisits then add the new counts to previous record
              if (
                _.filter(cmpDomainsData, { domain: element.domain }).length > 0
              ) {
                var currentDomainData = _.filter(cmpDomainsData, {
                  domain: element.domain,
                })[0];
                // count by events items first
                if (element.countByEvent["CMP cmploaded"]) {
                  currentDomainData.countByEvent["CMP cmploaded"]
                    ? (currentDomainData.countByEvent["CMP cmploaded"] =
                        currentDomainData.countByEvent["CMP cmploaded"] +
                        element.countByEvent["CMP cmploaded"])
                    : (currentDomainData.countByEvent["CMP cmploaded"] =
                        element.countByEvent["CMP cmploaded"]);
                }

                if (element.countByEvent["CMP consent-not-set"]) {
                  currentDomainData.countByEvent["CMP consent-not-set"]
                    ? (currentDomainData.countByEvent["CMP consent-not-set"] =
                        currentDomainData.countByEvent["CMP consent-not-set"] +
                        element.countByEvent["CMP consent-not-set"])
                    : (currentDomainData.countByEvent["CMP consent-not-set"] =
                        element.countByEvent["CMP consent-not-set"]);
                }

                if (element.countByEvent["CMP layer1:visible"]) {
                  currentDomainData.countByEvent["CMP layer1:visible"]
                    ? (currentDomainData.countByEvent["CMP layer1:visible"] =
                        currentDomainData.countByEvent["CMP layer1:visible"] +
                        element.countByEvent["CMP layer1:visible"])
                    : (currentDomainData.countByEvent["CMP layer1:visible"] =
                        element.countByEvent["CMP layer1:visible"]);
                }

                if (element.countByEvent["CMP layer1:opt-in:all"]) {
                  currentDomainData.countByEvent["CMP layer1:opt-in:all"]
                    ? (currentDomainData.countByEvent["CMP layer1:opt-in:all"] =
                        currentDomainData.countByEvent[
                          "CMP layer1:opt-in:all"
                        ] + element.countByEvent["CMP layer1:opt-in:all"])
                    : (currentDomainData.countByEvent["CMP layer1:opt-in:all"] =
                        element.countByEvent["CMP layer1:opt-in:all"]);
                }

                if (element.countByEvent["CMP layer1:opt-out:all"]) {
                  currentDomainData.countByEvent["CMP layer1:opt-out:all"]
                    ? (currentDomainData.countByEvent[
                        "CMP layer1:opt-out:all"
                      ] =
                        currentDomainData.countByEvent[
                          "CMP layer1:opt-out:all"
                        ] + element.countByEvent["CMP layer1:opt-out:all"])
                    : (currentDomainData.countByEvent[
                        "CMP layer1:opt-out:all"
                      ] = element.countByEvent["CMP layer1:opt-out:all"]);
                }

                if (element.countByEvent["CMP layer2:visible"]) {
                  currentDomainData.countByEvent["CMP layer2:visible"]
                    ? (currentDomainData.countByEvent["CMP layer2:visible"] =
                        currentDomainData.countByEvent["CMP layer2:visible"] +
                        element.countByEvent["CMP layer2:visible"])
                    : (currentDomainData.countByEvent["CMP layer2:visible"] =
                        element.countByEvent["CMP layer2:visible"]);
                }

                if (element.countByEvent["CMP layer2:opt-in:selected"]) {
                  currentDomainData.countByEvent["CMP layer2:opt-in:selected"]
                    ? (currentDomainData.countByEvent[
                        "CMP layer2:opt-in:selected"
                      ] =
                        currentDomainData.countByEvent[
                          "CMP layer2:opt-in:selected"
                        ] + element.countByEvent["CMP layer2:opt-in:selected"])
                    : (currentDomainData.countByEvent[
                        "CMP layer2:opt-in:selected"
                      ] = element.countByEvent["CMP layer2:opt-in:selected"]);
                }

                if (element.countByEvent["CMP layer2:opt-in:all"]) {
                  currentDomainData.countByEvent["CMP layer2:opt-in:all"]
                    ? (currentDomainData.countByEvent["CMP layer2:opt-in:all"] =
                        currentDomainData.countByEvent[
                          "CMP layer2:opt-in:all"
                        ] + element.countByEvent["CMP layer2:opt-in:all"])
                    : (currentDomainData.countByEvent["CMP layer2:opt-in:all"] =
                        element.countByEvent["CMP layer2:opt-in:all"]);
                }
                if (element.countByEvent["CMP layer2:opt-out:all"]) {
                  currentDomainData.countByEvent["CMP layer2:opt-out:all"]
                    ? (currentDomainData.countByEvent[
                        "CMP layer2:opt-out:all"
                      ] =
                        currentDomainData.countByEvent[
                          "CMP layer2:opt-out:all"
                        ] + element.countByEvent["CMP layer2:opt-out:all"])
                    : (currentDomainData.countByEvent[
                        "CMP layer2:opt-out:all"
                      ] = element.countByEvent["CMP layer2:opt-out:all"]);
                }

                if (element.countByEvent["CMP opt-in:previously"]) {
                  currentDomainData.countByEvent["CMP opt-in:previously"]
                    ? (currentDomainData.countByEvent["CMP opt-in:previously"] =
                        currentDomainData.countByEvent[
                          "CMP opt-in:previously"
                        ] + element.countByEvent["CMP opt-in:previously"])
                    : (currentDomainData.countByEvent["CMP opt-in:previously"] =
                        element.countByEvent["CMP opt-in:previously"]);
                }
                //changes for addtional event (reject all)
                if (element.countByEvent["CMP opt-in:previously:reject-all"]) {
                  currentDomainData.countByEvent[
                    "CMP opt-in:previously:reject-all"
                  ]
                    ? (currentDomainData.countByEvent[
                        "CMP opt-in:previously:reject-all"
                      ] =
                        currentDomainData.countByEvent[
                          "CMP opt-in:previously:reject-all"
                        ] +
                        element.countByEvent[
                          "CMP opt-in:previously:reject-all"
                        ])
                    : (currentDomainData.countByEvent[
                        "CMP opt-in:previously:reject-all"
                      ] =
                        element.countByEvent[
                          "CMP opt-in:previously:reject-all"
                        ]);
                }

                if (element.countByEvent["CMP layer1:show-settings"]) {
                  currentDomainData.countByEvent["CMP layer1:show-settings"]
                    ? (currentDomainData.countByEvent[
                        "CMP layer1:show-settings"
                      ] =
                        currentDomainData.countByEvent[
                          "CMP layer1:show-settings"
                        ] + element.countByEvent["CMP layer1:show-settings"])
                    : (currentDomainData.countByEvent[
                        "CMP layer1:show-settings"
                      ] = element.countByEvent["CMP layer1:show-settings"]);
                }

                if (element.countByEvent["CMP opt-in:previously:outdated"]) {
                  currentDomainData.countByEvent[
                    "CMP opt-in:previously:outdated"
                  ]
                    ? (currentDomainData.countByEvent[
                        "CMP opt-in:previously:outdated"
                      ] =
                        currentDomainData.countByEvent[
                          "CMP opt-in:previously:outdated"
                        ] +
                        element.countByEvent["CMP opt-in:previously:outdated"])
                    : (currentDomainData.countByEvent[
                        "CMP opt-in:previously:outdated"
                      ] =
                        element.countByEvent["CMP opt-in:previously:outdated"]);
                }
              }
              // else add the new entry
              else {
                cmpDomainsData.push(element);
              }

              // check if the cmp loaded event is returned
              if (element.countByEvent["CMP cmploaded"]) {
                totalcmpLoaded =
                  totalcmpLoaded + element.countByEvent["CMP cmploaded"];
              }

              if (element.countByEvent["CMP consent-not-set"]) {
                totalcmpConsentNotSet =
                  totalcmpConsentNotSet +
                  element.countByEvent["CMP consent-not-set"];
              }

              if (element.countByEvent["CMP layer1:visible"]) {
                totalcmpLayer1Visible =
                  totalcmpLayer1Visible +
                  element.countByEvent["CMP layer1:visible"];
              }

              if (element.countByEvent["CMP opt-in:previously:outdated"]) {
                totalcmpOutdated =
                  totalcmpOutdated +
                  element.countByEvent["CMP opt-in:previously:outdated"];
              }

              if (element.countByEvent["CMP layer1:show-settings"]) {
                totalLayer1ShowSettings =
                  totalLayer1ShowSettings +
                  element.countByEvent["CMP layer1:show-settings"];
              }

              if (element.countByEvent["CMP layer1:opt-in:all"]) {
                totalcmpLayer1OptinAll =
                  totalcmpLayer1OptinAll +
                  element.countByEvent["CMP layer1:opt-in:all"];
              }
              if (element.countByEvent["CMP layer1:opt-out:all"]) {
                totalcmpLayer1OptoutAll =
                  totalcmpLayer1OptoutAll +
                  element.countByEvent["CMP layer1:opt-out:all"];
              }
              if (element.countByEvent["CMP layer2:visible"]) {
                totalcmpLayer2Visible =
                  totalcmpLayer2Visible +
                  element.countByEvent["CMP layer2:visible"];
              }
              if (element.countByEvent["CMP layer2:opt-in:selected"]) {
                totalcmpLayer2OptinSelected =
                  totalcmpLayer2OptinSelected +
                  element.countByEvent["CMP layer2:opt-in:selected"];
              }
              if (element.countByEvent["CMP layer2:opt-in:all"]) {
                totalcmpLayer2OptinAll =
                  totalcmpLayer2OptinAll +
                  element.countByEvent["CMP layer2:opt-in:all"];
              }
              if (element.countByEvent["CMP layer2:opt-out:all"]) {
                totalcmpLayer2OptoutAll =
                  totalcmpLayer2OptoutAll +
                  element.countByEvent["CMP layer2:opt-out:all"];
              }
              if (element.countByEvent["CMP opt-in:previously"]) {
                totalcmpPreviousOption =
                  totalcmpPreviousOption +
                  element.countByEvent["CMP opt-in:previously"];
              }
            });
          } else {
          }
          cmpInfo.push(cmpDetails);
        });
      }

      var cmpStats = {
        loaded: totalcmpLoaded,
        layer1Visible: totalcmpLayer1Visible
          ? totalcmpLayer1Visible
          : totalcmpOutdated + totalcmpConsentNotSet,
        layer1OptinAll: totalcmpLayer1OptinAll,
        layer1OptoutAll: totalcmpLayer1OptoutAll,
        layer2Visible: totalcmpLayer2Visible,
        layer2OptinAll: totalcmpLayer2OptinAll,
        layer2OptoutAll: totalcmpLayer2OptoutAll,
        layer2Selected: totalcmpLayer2OptinSelected,
        previousOptin: totalcmpPreviousOption,
        totalcmpOutdated: totalcmpOutdated,
      };

      return {
        ...state,
        loading: false,
        cmpStats: cmpStats,
        consentdata: cmpDomainsData,
        filteredConsentdata:
          state.consentFilterDomain === null || state.consentFilterDomain === ""
            ? cmpDomainsData
            : _.filter(cmpDomainsData, {
                domain: state.consentFilterDomain,
              }),
      };
    });
    builder.addCase(getCustomerConsentReport.rejected, (state, action) => {});
    builder.addCase(getDomainLedgerReport.fulfilled, (state, action) => {
      var ledgerdata;
      if (action.payload === "") {
        ledgerdata = [];
      } else {
        ledgerdata = [];
        ledgerdata = JSON.parse(action.payload);
      }

      return { ...state, ledgerdata: ledgerdata, loading: false };
    });

    builder.addCase(getDomainEventReport.fulfilled, (state, action) => {
      if (action.payload === "") {
        return {
          ...state,
          domainReport: [],
          loading: false,
        };
      } else {
        return {
          ...state,
          domainReport: JSON.parse(action.payload),
          loading: false,
        };
      }
    });
  },

  //end
});

export const { filterCustomerConsentReport } = ReportingSlice.actions;
export default ReportingSlice.reducer;
