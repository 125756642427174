import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  getAxiosRequest,
  getContextObject,
  getAxiosRequestWithoutCredentials,
} from "../helpers/index";
import AppConfig from "../constants/appConfig";
import axios from "axios";

export const getCustomerDomains = createAsyncThunk(
  "domains/getCustomerDomains",
  async (model, thunkAPI) => {
    try {
      var currentCustomer = getContextObject()
        ? getContextObject().customerId
        : 0;
      let res = await getAxiosRequest().get(
        `/customer/${currentCustomer}/domain`
      );
      return res.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const generateDomainKey = createAsyncThunk(
  "domains/generateDomainKey",
  async (model, thunkAPI) => {
    try {
      let domain = { ...model };
      var config = {};
      config = {
        headers: { DOMAIN_KEY: domain.id },
      };
      let res = await getAxiosRequest().post(
        "/manage/domain/generatetoken",
        domain,
        config
      );
      return {
        accessToken: res.data,
        domainName: domain.name,
      };
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const getDomains = createAsyncThunk(
  "domains/getDomains",
  async (model, thunkAPI) => {
    try {
      const res = await getAxiosRequest().get("/domain");
      return res.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const requestDomainDeletion = createAsyncThunk(
  "domains/requestDomainDeletion",
  async (model, thunkAPI) => {
    try {
      var config = {};
      config = {
        headers: { DOMAIN_KEY: model.id },
      };
      console.log("model: ", model);
      console.log("config: ", config);
      const res = await getAxiosRequest().post(
        "/manage/domain/deletionrequest?id=" + model.id,
        {},
        config
      );
      return res.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const testDomainWebhook = createAsyncThunk(
  "domains/testWebhook",
  async (model, thunkAPI) => {
    try {
      let payload = AppConfig.webhookPayload;
      payload.Domain = model.currentUserDomain;
      payload.UpdatedTs = new Date().getTime();

      let webhookModel = {
        requestPayload: payload,
        domain: model.currentDomain,
      };

      console.log("webhookModel: ", webhookModel);

      var config = {
        headers: {},
      };
      let postUrl = "";
      // get details from selected domain..
      if (webhookModel.domain) {
        if (webhookModel.domain.consentChangeWebHook) {
          postUrl = webhookModel.domain.consentChangeWebHook.url;
          if (webhookModel.domain.consentChangeWebHook.headerValues) {
            webhookModel.domain.consentChangeWebHook.headerValues.map(
              (item, index) => {
                config.headers[item.header] = item.value;
              }
            );
          }
        }
      }
      if (postUrl !== "") {
        console.log("postUrl: ", postUrl);
        getAxiosRequestWithoutCredentials()
          .post(postUrl, webhookModel.requestPayload, config)
          .then((res) => {
            console.log("res: ", res);
            return res.data;
          })
          .catch((err) => {
            console.log("err: ", err);
            return err;
          });
      } else {
        return null;
      }
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const updateDomain = createAsyncThunk(
  "domains/editDomain",
  async (model, thunkAPI) => {
    try {
      var config = {};
      config = {
        headers: { DOMAIN_KEY: model.id },
      };
      const res = await getAxiosRequest().put("/manage/domain", model, config);
      return res.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const getCustomerDomainGroups = createAsyncThunk(
  "domains/getCustomerDomainGroups",
  async (model, thunkAPI) => {
    try {
      var currentCustomer = getContextObject()
        ? getContextObject().customerId
        : 0;
      let res = await getAxiosRequest().get(
        `/customer/${currentCustomer}/getDomainGroup`
      );
      let data = JSON.parse(res.data);
      return data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const createDomainGroups = createAsyncThunk(
  "domains/createCustomerDomainGroups",
  async (model, thunkAPI) => {
    try {
      var currentCustomer = getContextObject()
        ? getContextObject().customerId
        : 0;
      try {
        var payload = {
          json: JSON.stringify(model.domains),
          customerId: currentCustomer,
          groupName: model.groupName,
          createdBy: model.createdBy,
        };
        let res = await getAxiosRequest().post(
          "/customer/createDomainGroup",
          payload
        );
        return res?.data;
      } catch (error) {
        return thunkAPI.rejectWithValue(error);
      }
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const updateDomainGroups = createAsyncThunk(
  "domains/updateCustomerDomainGroups",
  async (model, thunkAPI) => {
    try {
      var currentCustomer = getContextObject()
        ? getContextObject().customerId
        : 0;
      try {
        var payload = {
          json: JSON.stringify(model.domains),
          customerId: currentCustomer,
          groupName: model.groupName,
          groupId: model.groupId,
          createdBy: model.createdBy,
        };
        let res = await getAxiosRequest().put(
          "/customer/updateDomainGroup",
          payload
        );
        return res?.data;
      } catch (error) {
        return thunkAPI.rejectWithValue(error);
      }
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const deleteDomainGroups = createAsyncThunk(
  "domains/deleteDomainGroups",
  async (model, thunkAPI) => {
    try {
      var currentCustomer = getContextObject()
        ? getContextObject().customerId
        : 0;
      let res = await getAxiosRequest().delete(
        `/customer/${currentCustomer}/deleteDomainGroup?groupId=${model.groupId}`
      );
      return res.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);
