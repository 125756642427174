import { createAsyncThunk } from "@reduxjs/toolkit";
import { getAxiosRequest } from "../helpers/index";

export const getApplications = createAsyncThunk(
  "application/getApplications",
  async (model, thunkAPI) => {
    try {
      var config = {};
      config = {
        headers: { DOMAIN_KEY: model.id },
      };
      let res = await getAxiosRequest().get(
        "/manage/client/customer/" + model.customerId,
        config
      );
      return res.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const deleteApplication = createAsyncThunk(
  "application/deleteApplication",
  async (model, thunkAPI) => {
    try {
      var config = {};
      config = {
        headers: { DOMAIN_KEY: model.domain },
      };
      let res = await getAxiosRequest().delete(
        "/manage/client/" + model.id,
        config
      );
      return res.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const updateApplication = createAsyncThunk(
  "application/updateApplication",
  async (model, thunkAPI) => {
    try {
      var config = {};
      config = {
        headers: { DOMAIN_KEY: model.domain },
      };
      let res = await getAxiosRequest().put(
        "/manage/client",
        model.application,
        config
      );
      return res.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const addApplication = createAsyncThunk(
  "application/addApplication",
  async (model, thunkAPI) => {
    try {
      var config = {};
      config = {
        headers: { DOMAIN_KEY: model.domain },
      };
      let res = await getAxiosRequest().post(
        "/manage/client",
        model.application,
        config
      );
      return res.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);
