import { createAsyncThunk } from "@reduxjs/toolkit";
import { getAxiosRequest } from "../helpers/index";
import axios from "axios";

import localSchema from "../pages/liteCmpConfig/schema/primary";

export const fetchLiteConfiguratorSchema = createAsyncThunk(
  "liteCmpConfig/fetchLiteConfiguratorSchema",
  async (model, thunkAPI) => {
    try {
      let res;
      res = await axios.get(
        "https://cdn.gravito.net/configmappers/gravitoSchema.json"
      );
      return res.data;
      // return localSchema;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const addLightConfiguration = createAsyncThunk(
  "liteCmpConfig/addLightConfiguration",
  async (model, thunkAPI) => {
    try {
      var config = {};
      config = {
        headers: {
          DOMAIN_KEY: model.domain,
          "content-type": "application/json",
        },
      };
      let res = await getAxiosRequest().put(
        `/cmpconfigurator/lightconfig`,
        model,
        config
      );
      return res.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const getLightConfigurations = createAsyncThunk(
  "liteCmpConfig/getLightConfigurations",
  async (model, thunkAPI) => {
    try {
      var config = {};
      config = {
        headers: { DOMAIN_KEY: model.id },
      };
      let res = await getAxiosRequest().get(
        `/cmpconfigurator/lightconfig/${model.customerId}`,
        config
      );
      return res.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const fetchLightCMPConfigById = createAsyncThunk(
  "liteCmpConfig/fetchLightCMPConfigById",
  async (model, thunkAPI) => {
    try {
      var config = {};
      config = {
        headers: {
          "content-type": "application/json",
        },
      };
      let res = await getAxiosRequest().get(
        `/cmpconfigurator/${model.id}`,
        config
      );
      return res.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const deleteLightConfiguration = createAsyncThunk(
  "liteCmpConfig/deleteLightConfiguration",
  async (model, thunkAPI) => {
    try {
      let path = model.path.replace("light/", "");
      let res = await getAxiosRequest().delete(
        `/cmpconfigurator/lightconfig/${path}`
      );
      return res.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const deleteLightConfigurationDraft = createAsyncThunk(
  "liteCmpConfig/deleteLightConfigurationDraft",
  async (model, thunkAPI) => {
    try {
      let res = await getAxiosRequest().delete(
        `/cmpconfigurator/lightconfig/${model.customerId}/draft/${model.id}`
      );
      return res.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const addLightConfigurationDraft = createAsyncThunk(
  "liteCmpConfig/addLightConfigurationDraft",
  async (model, thunkAPI) => {
    try {
      let res = await getAxiosRequest().post(
        `/cmpconfigurator/lightconfig/draft`,
        model
      );
      return res.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const updateLightConfigurationDraft = createAsyncThunk(
  "liteCmpConfig/updateLightConfigurationDraft",
  async (model, thunkAPI) => {
    try {
      let res = await getAxiosRequest().put(
        `/cmpconfigurator/lightconfig/draft`,
        model
      );
      return res.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);
