import { createAsyncThunk } from "@reduxjs/toolkit";
import { getAxiosRequest } from "../helpers";

// const getTrendsCountFromServer = async (model) => {
//   var payload = {
//     noOfDays: model.days,
//     domainList: model.filterDomains ? model.filterDomains : [],
//   };

//   return await getAxiosRequest().post(
//     `/customer/${model.customerId}/domaincounts`,
//     payload
//   );
// };

const getTrendsCountFromServer = async (model) => {
  var payload = {
    startDate: model.startDate,
    endDate: model.endDate,
    domainList: model.filterDomains ? model.filterDomains : [],
  };

  return await getAxiosRequest().post(
    `/customer/${model.customerId}/domaincountsfordates`,
    payload
  );
};

// const getTrendsEventsFromServer = async (model) => {
//   var payload = {
//     noOfDays: model.days,
//     domainList: model.filterDomains ? model.filterDomains : [],
//   };

//   return await getAxiosRequest().post(
//     `/customer/${model.customerId}/domaineventcounts`,
//     payload
//   );
// };

const getTrendsEventsFromServer = async (model) => {
  var payload = {
    startDate: model.startDate,
    endDate: model.endDate,
    domainList: model.filterDomains ? model.filterDomains : [],
  };

  return await getAxiosRequest().post(
    `/customer/${model.customerId}/domaineventcountsfordates`,
    payload
  );
};

export const getTrendsData = createAsyncThunk(
  "compareTrendsThunk/getTrendsData",
  async (model, thunkAPI) => {
    try {
      const [
        groupOneCdpData,
        groupTwoCdpData,
        groupOneCmpData,
        groupTwoCmpData,
      ] = await Promise.all([
        getTrendsCountFromServer(model.groupOneModel),
        getTrendsCountFromServer(model.groupTwoModel),
        getTrendsEventsFromServer(model.groupOneModel),
        getTrendsEventsFromServer(model.groupTwoModel),
      ]);

      var response = {
        groupOneCdp: groupOneCdpData.data,
        groupTwoCdp: groupTwoCdpData.data,
        groupOneCmp: groupOneCmpData.data,
        groupTwoCmp: groupTwoCmpData.data,
      };
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);
